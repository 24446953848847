import React, { useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import Tools from '../tools/tools'

const IPSelect = (props) => {

    const [val, setVal] = useState(props.obj.value);
    const [items, setItems] = useState(null);
    const change = (val) => {
        setVal(val);
        props.setValue(props.obj.id, val)
    }

    if (!items) {
        Tools.ReloadIfOld(props.obj.items, setItems); 

        return (
            <div className="form-group col-md-12">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={!items}
                />
            </div>
        )
    }



    return (
        <div className="form-group col-md-12">
            <label>{props.obj.label}</label>
            <select type="text" value={val} onChange={(e) => change(e.target.value)} className="form-control" readOnly={props.readonly} >
                <option value="0" key="0"></option>
                {
                    items.map(item => <option value={item.key} key={item.key}>{item.label}</option>)
                }
            </select>
        </div>

    );
}


export default IPSelect;