import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import Comm from '../comm/comm';
import { Grid } from '@material-ui/core';
import DDL from './form/DDL';

const ModalMoveDocument = (props) => {
    let [selProtocol, setSelProtocol] = useState(0);
    let [selProject, setSelProject] = useState(0);
    let [selDossier, setSelDossier] = useState(0);
    let [selDossierLinkType, setDossierLinkType] = useState(0);
    let [protocols, setProtocols] = useState([]);
    let [projects, setProjects] = useState([]);
    let [dossiers, setDossiers] = useState([]);

    const dossierPlaces = [
        { id: 1, name: "Документи за категоризация" },
        { id: 2, name: "Документи за класификация" },
        { id: 3, name: "Документи за проучване" },
        { id: 4, name: "Други документи" },
    ]


    const chooseElement = (id, type) => {
        switch (type) {
            case "protocol":
                setSelProtocol(id);
                setSelProject(0);
                setSelDossier(0);
                break;
            case "project":
                setSelProject(id);
                setSelProtocol(0);
                setSelDossier(0);
                break;
            case "dossier":
                setSelDossier(id);
                setSelProtocol(0);
                setSelProject(0);
                break;
            default: break;
        }

    }


    const fetchData = async () => {
        Comm.Instance().get("/backend/sis/secure/project/selected")
            .then(r => {
                setProjects(r.data.rows);

            });
        Comm.Instance().get("/backend/sis/secure/minutes/selected")
            .then(r => {
                setProtocols(r.data.rows);

            });
        Comm.Instance().get("/backend/sis/secure/artifact/selected")
            .then(r => {
                for(let item of r.data.rows){
                    item.name = item.main.name;
                }
                setDossiers(r.data.rows);

            });

    };


    useEffect(() => {

        fetchData();
    }, []);



    const canShowMove = () => {
        return selProtocol !== 0 || selProject !== 0 ||
            (selDossier !== 0 && selDossierLinkType !== 0);
    }

    const move = () => {
        if (selProtocol !== 0) {
            let data = {
                documentId: props.documentid,
                minutesId: selProtocol
            };
            Comm.Instance().post("/backend/sis/secure/minutes/file", data)
                .then(r => {
                    props.closeFunc(props.documentid);
                })
        }
        if (selProject !== 0) {
            let data = {
                documentId: props.documentid,
                projectId: selProject
            };
            Comm.Instance().post("/backend/sis/secure/project/file", data)
                .then(r => {
                    props.closeFunc(props.documentid);
                })
        }
        if (selDossier !== 0) {
            let data = {
                documentId: props.documentid,
                artifactId: selDossier,
                "isArtifactCategorization": selDossierLinkType===1,
                "isArtifactClassification": selDossierLinkType===2,
                "isArtifactMisc": selDossierLinkType===4,
                "isArtifactStudy": selDossierLinkType===3,
            };

            Comm.Instance().post("/backend/sis/secure/artifact/file", data)
                .then(r => {
                    props.closeFunc(props.documentid);
                })
        }
    }

    return (
        <Dialog header={props.header} visible={props.visible} style={{ width: '50vw' }} footer="" onHide={() => props.closeFunc(null)}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <DDL label="Досие"
                        value={selDossier}
                        change={(e) => chooseElement(e, "dossier")}
                        items={dossiers}
                        idfield="id"
                        labelfield="name"
                        readonly={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DDL label="Част"
                        value={selDossierLinkType}
                        change={(e) => setDossierLinkType(e)}
                        items={dossierPlaces}
                        idfield="id"
                        labelfield="name"
                        readonly={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DDL label="Протокол"
                        value={selProtocol}
                        change={(e) => chooseElement(e, "protocol")}
                        items={protocols}
                        idfield="id"
                        labelfield="name"
                        readonly={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DDL label="Проект"
                        value={selProject}
                        change={(e) => chooseElement(e, "project")}
                        items={projects}
                        idfield="id"
                        labelfield="name"
                        readonly={false}
                    />
                </Grid>
            </Grid>

            {canShowMove() && <button className="btn btn-primary" onClick={() => move()}>Преместване</button>}

        </Dialog>

    );
}


export default ModalMoveDocument;

