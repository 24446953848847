import React from 'react';
import MultiTB from './form/MultiTB';

const IPTextArea = (props) => {

    return <>
        <MultiTB value={props.obj.value} change={(e) => props.setValue(props.obj.id, e)} label={props.obj.label} />
    </>
}

export default IPTextArea;
