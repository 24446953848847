import { Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import React from 'react';
import TB from './form/TB';

const Coordinates = ({ coordinates, setCoordinateValue, addCoordinates, removeCoordinates, readOnly }) => {

    return (

        <Grid container spacing={3}>
            <Grid item xs={10}>
                <h5>Координати</h5>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={() => addCoordinates()}><AddIcon></AddIcon></Button>
            </Grid>
            {
                coordinates.map((item, index) =>
                    <React.Fragment key={index}>
                        <Grid item xs={5}>
                            <TB label="Географска ширина" value={item.lat} change={(e) => setCoordinateValue(index, 'lat', e)} readonly={readOnly} />
                        </Grid>

                        <Grid item xs={5}>
                            <TB label="Географска дължина" value={item.lng} change={(e) => setCoordinateValue(index, 'lng', e)} readonly={readOnly} />
                        </Grid>
                        <Grid item xs={2}>
                            <Button onClick={() => removeCoordinates(index)}><DeleteForeverIcon></DeleteForeverIcon></Button>
                        </Grid>

                    </React.Fragment>
                )
            }
        </Grid>
    )
}

export default Coordinates;