import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { Card } from 'primereact/card';
import FakeData from '../fakedata/FakeData';
import useStateWithCallback from 'use-state-with-callback';
import { Dialog } from 'primereact/dialog';
import DataDesignerElement from '../controls/DataDesignerElement';
import PropertiesContainer from './PropertiesContainer';

const DataDesigner = (props) => {

    let [currentObject, setCurrentObject] = useState();
    let [objects, setObjects] = useState();
    let [objectId, setObjectId] = useState("0");
    let [showModal, setShowModal] = useState(false);
    let [showDemoModal, setShowDemoModal] = useState(false);
    
    const [currentElement, setCurrentElement] = useStateWithCallback(null, currentElement => {
        if (currentElement) {
            setShowModal(true);
        }
        else {
            setShowModal(false);
        }
    });




    const fetchData = async () => {
        setObjects(FakeData.additionalDataObjects);

    };
    useEffect(() => {

        fetchData();
    }, []);

    if (!objects)
        return (
            <div className="container">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={true}
                />
            </div>
        );


    const setObject = (objectId) => {
        setObjectId(objectId);
        setCurrentObject({ ...FakeData.additionalDataObjects.filter(x => x.objectId === objectId)[0] })
    }

    const DeleteElement = (id) => {
        currentObject.elements = currentObject.elements.filter(x => x.id !== id);
        setCurrentObject({ ...currentObject });

    }

    const SaveElement = (obj) => {
        var ix = currentObject.elements.findIndex(x => x.id === obj.id);
        if (ix === -1) {
            currentObject.elements.push(obj);
        }
        else {
            currentObject.elements[ix] = obj
        }

        setCurrentElement(null);

    }

    return (
        <div className="container">
            <Card title="Обект">
                <select className="form-control" value={objectId} onChange={(e) => setObject(e.target.value)} disabled={objectId !== "0"}>
                    <option value="0"></option>
                    {
                        objects.map(i => <option value={i.objectId}>{i.label}</option>)
                    }
                </select>

            </Card>
            {
                objectId !== "0" ?
                    <Card title="Елементи">
                        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="dropdown">
                                <button class="btn btn-danger dropdown-toggle" type="button" id="btnNew" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Нов
                              </button>
                                <div class="dropdown-menu" aria-labelledby="btnNew">
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "check", content: {}, canEditId: true })}>Опция</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "checklist", content: {}, canEditId: true })}>Списък опции</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "date", content: {}, canEditId: true })}>Дата</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "email", content: {}, canEditId: true })}>Имейл</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "number", content: {}, canEditId: true })}>Число</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "select", content: {}, canEditId: true })}>Падащ списък</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "string", content: {}, canEditId: true })}>Стринг</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "text", content: {}, canEditId: true })}>Текст</button>
                                    <button class="dropdown-item" onClick={() => setCurrentElement({ id: "", type: "tree", content: {}, canEditId: true })}>Йерархия</button>
                                </div>
                            </div>
                            <button class="btn btn-secondary" onClick={() => setShowDemoModal(true)}>
                                Демо
                              </button>
                        </div>

                        <ul className="list-group">
                            {
                                currentObject.elements.map(i => <li className="list-group-item" key={i.id}>
                                    <button class="btn btn-info" onClick={() => setCurrentElement({ id: i.id, type: i.type, content: i, canEditId: false })}>{i.label}</button>
                                    <button class="btn btn-danger" onClick={() => DeleteElement(i.id)}>X</button>

                                </li>)

                            }
                        </ul>

                    </Card>
                    : null
            }

            <Dialog header="Елемент" visible={showModal} style={{ width: '100vw' }} footer="" onHide={() => setCurrentElement(null)} >
                <DataDesignerElement content={currentElement ? currentElement.content : null}
                    caneditid={currentElement ? currentElement.canEditId : null}
                    type={currentElement ? currentElement.type : null}
                    saveFunc={SaveElement}
                ></DataDesignerElement>
            </Dialog>
            <Dialog header="Демо" visible={showDemoModal} style={{ width: '100vw' }} footer="" onHide={() => setShowDemoModal(false)} >
                <PropertiesContainer readonly={true} obj={currentObject ? currentObject.elements : null} />
            </Dialog>

        </div>



    )



}

export default DataDesigner;