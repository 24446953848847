import React, { useState } from 'react';
import EkatteEdit from '../controls/EkatteEdit';
import { Redirect } from 'react-router-dom';
import { Grid, Paper, Button } from '@material-ui/core';
import TB from '../controls/form/TB';
import Comm from '../comm/comm';
import IPGrid from '../controls/grid/IPGrid';
import DossierHeader from '../controls/grid/DossierHeader';
import DossierRow from '../controls/grid/DossierRow';

const DossierSearch = (props) => {
    let [objectName, setObjectName] = useState("");
    let [objectNumber, setObjectNumber] = useState("");
    let [ss, setss] = useState("");
    let [oldIndex, setOldIndex] = useState("");
    let [ekatteId, setEkatteId] = useState("0");
    let [showObject, setShowObject] = useState(null);
    let [searchParams, setSearchParams] = useState({
        func: "/backend/sis/secure/artifact/top/active/10",
        export: {
            xlsx: "/backend/sis/secure/artifact/search/xlsx"
        },
        params: {}
    });
    let [refresh, setRefresh] = useState(new Date());




    const Search = () => {
        setSearchParams({
            func: "/backend/sis/secure/artifact/search",
            export: {
                xlsx: "/backend/sis/secure/artifact/search/xlsx"
            },
            params: {
                // eslint-disable-next-line eqeqeq
                "ekatteId": ekatteId == "0" ? null : ekatteId,
                "title": objectName === "" ? null : objectName,
                "objectNumber": objectNumber === "" ? null : objectNumber,
                "ss": ss === "" ? null : ss,
                "registryIndexOld": oldIndex === "" ? null : oldIndex,
            }
        })

    };


    const NewDocument = () => {
        setShowObject("-1");
    }


    const Delete = (id) => {
        Comm.Instance().delete("backend/sis/secure/artifact?reference=" + id).then(r => {
            setRefresh(new Date());
        });
    }


    if (showObject) {
        if (showObject !== "-1") {
            return (<Redirect to={"Dossier/" + showObject} />);
        }
        else {
            return (<Redirect to={"Dossier"} />);
        }
    }





    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Търсене на досиета</h2>
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <TB label="Наименование на обект" value={objectName} change={setObjectName} />
                    </Grid>
                    <Grid item xs={4}>
                        <TB label="Номер на обект" value={objectNumber} change={setObjectNumber} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <TB label="В текста" value={ss} change={setss} />
                    </Grid>
                    <Grid item xs={3}>
                        <TB label="Стар индекс" value={oldIndex} change={setOldIndex} />
                    </Grid>
                    {/* <Grid item xs={4}>
                        <TB label="Адрес" value={address} change={setAddress} /> 
                    </Grid>
                    <Grid item xs={4}>
                        <TB label="Кадастър" value={cadastreData} change={setCadastreData} />
                    </Grid> */}
                </Grid>
                <EkatteEdit ekatteId={ekatteId} setValue={setEkatteId} readonly={false} />
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => Search()}>
                            Търсене
                        </Button>
                    </Grid>
                    <Grid item xs={8}>

                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="secondary" onClick={() => NewDocument()}>
                            Ново досие
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Paper>
                <IPGrid searchParams={searchParams}
                    refresh={refresh}
                    header={() => <DossierHeader></DossierHeader>}
                    row={(item) => <DossierRow item={item} deleteFunc={Delete}></DossierRow>}>

                </IPGrid>
            </Paper>

        </div>
    );
}


export default DossierSearch;
