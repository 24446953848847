import { Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import Comm from '../comm/comm';
import tools from '../tools/tools';
import ReactHtmlParser from 'react-html-parser';


const NADisplay = (props) => {
    const [act, setAct] = useState();
    useEffect(
        () => {
            Comm.InstanceForNormact().get("/api/ciela/GetDoc?naid=" + props.match.params.naid)
                .then(res => {
                    setAct(res.data);                    
                })
                .catch(error => tools.ProcessError(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )


    if (!act) {

        return (
            <Paper>
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={true}
                />
            </Paper>
        );
    }
    return (
        <div className="container">

            <Paper>
                {/* <Typography variant="h3" align="center">{act.title}</Typography> */}
                <div>{ReactHtmlParser(act)}</div>
            </Paper>
        </div>
    );
}

export default NADisplay;