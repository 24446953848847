import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import keycloak from './tools/keycloak';


keycloak.init({ onLoad: 'login-required', promiseType: 'native' }).then(authenticated => {

    if (!authenticated) {
        window.location.reload();
    } else {
        //console.info("Authenticated");
    }

    const rootElement = document.getElementById('root');

    ReactDOM.render(
                <App />
            
        ,
        rootElement);

    sessionStorage.setItem('authentication', keycloak.token);
    sessionStorage.setItem('refreshToken', keycloak.refreshToken);

    //to regenerate token on expiry
    setInterval(() => {
        keycloak.updateToken(70);


    }, 30000)

})