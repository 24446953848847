/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import EkatteEdit from '../controls/EkatteEdit';
import { Redirect } from 'react-router-dom';
import { Grid, Paper, Button } from '@material-ui/core';
import TB from '../controls/form/TB';
import Comm from '../comm/comm';
import SelectedObjects from '../controls/SelectedObjects';
import IPGrid from '../controls/grid/IPGrid';
import ProjectHeader from '../controls/grid/ProjectHeader';
import ProjectRow from '../controls/grid/ProjectRow';



const ProjectSearch = (props) => {
    let [dossier, setDossier] = useState(0);
    let [ss, setss] = useState("");
    let [ekatteId, setEkatteId] = useState("0");
    let [showObject, setShowObject] = useState(null);
    let [title, setTitle] = useState("");
    let [searchParams, setSearchParams] = useState({
        func: "/backend/sis/secure/project/top/active/10",
        export: {
            xlsx: "/backend/sis/secure/project/search/xlsx"
        },
        params: {}
    });
    let [refresh, setRefresh] = useState(new Date());



    const Search = () => {
        setSearchParams({
            func: "/backend/sis/secure/project/search",
            export: {
                xlsx: "/backend/sis/secure/project/search/xlsx"
            },
            params: {
                "ekatteId": ekatteId === "0" ? null : ekatteId,
                "artifactReference": dossier == "0" ? null : dossier,
                "ss": ss === "" ? null : ss,
                "title": title === "" ? null : title
            }
        })
        
    };





    const Delete = (id) => {
        Comm.Instance().delete("backend/sis/secure/project?reference=" + id).then(r => {
            setRefresh(new Date());
        });
    }

  
    if (showObject) {
        return (<Redirect to={"Project/" + (showObject === -1 ? "" : showObject)} />);
    }





    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Търсене на проекти</h2>
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <TB label="Име" value={title} change={setTitle} />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectedObjects label="Досие" value={dossier} setValue={setDossier} objectType="dossiers" />
                    </Grid>
                    <Grid item xs={6}>
                        <TB label="В текста" value={ss} change={setss} />
                    </Grid>
                </Grid>
                <EkatteEdit ekatteId={ekatteId} setValue={setEkatteId} readonly={false} />
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => Search()}>
                            Търсене
                        </Button>
                    </Grid>
                    <Grid item xs={8}>

                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="secondary" onClick={() => setShowObject(-1)}>
                            Нов проект
                        </Button>
                    </Grid>
                </Grid>
            </Paper>


            <Paper>
                <IPGrid searchParams={searchParams}
                    refresh={refresh}
                    header={() => <ProjectHeader></ProjectHeader>}
                    row={(item) => <ProjectRow item={item} deleteFunc={Delete}></ProjectRow>}>

                </IPGrid>
            </Paper>
        </div>
    );
}


export default ProjectSearch;