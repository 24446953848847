/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import { Button, Grid } from '@material-ui/core';
import TB from '../controls/form/TB';
import { useRecoilState } from 'recoil';
import ToastMessageAtom from '../atoms/ToastMessageAtom';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DocumentList from '../controls/DocumentList';
import DDL from '../controls/form/DDL';
import Comm from '../comm/comm';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ModalAttachToDossier from '../controls/ModalAttachToDossier';

const Project = (props) => {
    let [id, setId] = useState(props.match.params.projectId);
    let [document, setDocumentState] = useState();
    let [page, setPage] = useState(1);
    let [history, setHistory] = useState();
    let [ro, setRO] = useState(false);
    let [showSave, setShowSave] = useState(false);
    let [showAttachDialog, setShowAttachDialog] = useState(false)
    const [, setSuccess] = useRecoilState(ToastMessageAtom);

    const years = useMemo(() => {
        let result = [];
        for (let y = new Date().getFullYear(); y > 1900; y--) {
            result.push({ year: "" + y });
        }
        return result;
    })

    const setDocument = (obj) => {
        setDocumentState(obj);
        setShowSave(true);
    }


    useEffect(() => {
        if (props.match.params.projectId) {
            let getParams = "reference=" + props.match.params.projectId;
            if (props.match.params.versionId) {
                getParams += "&versionId=" + props.match.params.versionId;
                setRO(true)
            }
            Comm.Instance().get("/backend/sis/secure/project?" + getParams)
                .then(r => {
                    let doc = {};

                    doc = r.data;
                    Comm.Instance().post("/backend/sis/secure/file/search", { projectReference: props.match.params.projectId })
                        .then(r => {
                            doc.documents = r.data.rows;
                            setDocument(doc);
                            setShowSave(false);
                        });


                });
            Comm.Instance().get("backend/sis/secure/polymorphic/project?projectId=" + props.match.params.projectId)
                .then(r => {
                    setHistory(r.data);
                });

        } else {
            setDocument({
                "date": moment(new Date()).format("YYYY-MM-DD"),
                "documents": [],
                "name": "",
                "selected": false

            });
            setShowSave(false);
        }

    }, [props.match.params.projectId, props.match.params.versionId]);



    const Validate = () => {
        let result = [];
        if ((document.name || "") === "") {
            result.push("Моля, въведете име");
        }

        return result;
    }

    const Save = () => {
        const validate = Validate();
        if (validate.length === 0) {
            Comm.Instance().post("/backend/sis/secure/project", {
                "year": document.year,
                "documents": document.documents,
                "id": id,
                "name": document.name,
                "selected": document.selected
            })
                .then(r => setId(r.data.id));
            setShowSave(false);
            setSuccess({ message: "Данните бяха успешно записани", type: "success" });
        }
        else {
            setSuccess({ message: validate, type: "error" });
        }
    }


    const setData = (id, value) => {


        document[id] = value;
        setDocument({ ...document });
        setShowSave(true);

    };












    const AddDocument = (doc) => {
        document.documents.push({ ...doc });
        setDocument(JSON.parse(JSON.stringify(document)));
    }

    const DeleteDocument = (id, save = false) => {
        document.documents = document.documents.filter(x => x.documentId !== id);
        setDocument(JSON.parse(JSON.stringify(document)));
        if (save) {
            Save();
        }
    }



    const changeSelection = () => {
        document.selected = !document.selected;
        setDocument({ ...document });
        Comm.Instance().post("/backend/sis/secure/project/selected", {
            "projectId": id,
            "selected": document.selected
        })

    }

    const tabClass = (id) => {
        return page === id ? "btn btn-info" : "btn btn-secondary";
    }

    const attachToDossier = (dossierId, linkType) => {
        Comm.Instance().post("/backend/sis/secure/artifact/project", {
            projectId: props.match.params.projectId,
            artifactId: dossierId

        })


        setShowAttachDialog(false);
    }


    if (!document)
        return (
            <div className="container">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={true}
                />
            </div>
        );



    return (

        <div className="container">
            <h2 className="appeal-title mb-5">Проект  - {document.name}</h2>
            <div className="tabcontainer">
                <button className={tabClass(1)} onClick={() => setPage(1)} type="button">Основни данни</button>&nbsp;
                <button className={tabClass(2)} onClick={() => setPage(2)} type="button">Документи</button>&nbsp;
                <button className={tabClass(3)} onClick={() => setPage(3)} type="button">История</button>
            </div>



            {page === 1 &&
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <TB label="Име" value={document.name} change={(v) => setData("name", v)} readonly={ro} />
                    </Grid>

                    <Grid item xs={4}>
                        <DDL label="Избери година"
                            value={document.year}
                            change={(v) => setData("year", v)}
                            items={years}
                            idfield="year"
                            labelfield="year"
                            readonly={ro}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <TB label="Фаза/част" value={document.discipline} change={(v) => setData("discipline", v)} readonly={ro} />
                </Grid>

                

                </Grid>
            }
            {
                page === 2 &&
                <DocumentList documents={document.documents} deleteFunc={DeleteDocument} addFunc={(doc) => AddDocument(doc)} hasPhase={false} readonly={ro}

                ></DocumentList>


            }
            {

                page === 3 && history &&
                history.rows.map(item => <p><Link to={`/project/${item.subjectReference}/${item.subjectId}`}>{moment(item.createdOn).format("DD.MM.YYYY hh:mm")} промяна от {item.createdBy}</Link></p>)
            }


            <Grid container spacing={3}>
                <Grid item xs={3}>
                    {showSave && <Button onClick={() => Save()} color="primary" variant="contained">Запис</Button>}
                    {
                        props.match.params.projectId && <Button onClick={() => setShowAttachDialog(true)} color="primary" variant="contained">Прикачи към досие</Button>
                    }
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                    {!document.selected && <Button onClick={() => changeSelection()} ><CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>Избор</Button>}
                    {document.selected && <Button onClick={() => changeSelection()} ><CheckBoxIcon></CheckBoxIcon>Премахване от избрани</Button>}
                </Grid>
            </Grid>
            <ModalAttachToDossier linkType={false} closeFunc={attachToDossier} visible={showAttachDialog}></ModalAttachToDossier>
        </div>
    );
}


export default Project;