import { TextField } from '@material-ui/core';
import React from 'react';

const MultiTB = (props) => {

    return <TextField label={props.label} variant="outlined" value={props.value} onChange={(e) => props.change(e.target.value)} fullWidth multiline rows={7}
        InputProps={{
            readOnly: props.readonly,
        }}

    />
}

export default MultiTB;
