/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TB from './form/TB';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DDL from './form/DDL';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Comm from '../comm/comm';

const ProjectList = ({ deleteFunc, addFunc, readonly, projects }) => {
    const step = 20;
    let [showCount, setShowCount] = useState(step);
    let [ss, setSS] = useState("");
    let [documents, setDocuments] = useState([])
    let [selectedDocument, setSelectedDocument] = useState(0)
    let [selectedDocuments, setSelectedDocuments] = useState([])
    let [expanded, setExpanded] = useState(new Set());



    useEffect(() => {
        setDocuments(projects);
        Comm.Instance().get("/backend/sis/secure/project/selected").then(r => setSelectedDocuments(r.data.rows))

    }, [projects])

    const filterDocuments = () => {
        let result = (projects || [])
            .filter(x => (ss || "") === "" || x.title.toLowerCase().indexOf(ss.toLowerCase()) > -1);
        //.slice(0, Math.min(props.documents.length, showCount) - 1);
        setDocuments([...result]);

    }

    const handleExpand = (projectId) => {
        if (expanded.has(projectId)) {
            expanded.delete(projectId);

        }
        else {
            expanded.add(projectId);
        }
        setExpanded(new Set(Array.from(expanded)));
    }



    return (
        <div className="container">
            <Grid
                container
                spacing={3}
            >

                <Grid item xs={10}>

                    <TB label="Търсене..." value={ss} change={(v) => setSS(v)}></TB>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={() => filterDocuments()}><SearchIcon></SearchIcon></Button>
                </Grid>
                <Grid item xs={10}>

                    <DDL label="Избери проект"
                        value={selectedDocument}
                        change={(e) => setSelectedDocument(e)}
                        items={selectedDocuments}
                        idfield="id"
                        labelfield="name"

                    />
                </Grid>
                <Grid item xs={2}>
                    {
                        selectedDocument !== 0 && <Button onClick={() => addFunc(selectedDocument)}><AddIcon></AddIcon></Button>
                    }

                </Grid>
                <Grid item xs={12}>


                    <b>{documents.length} записа</b>
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            documents.slice(0, Math.min(documents.length, showCount)).map(x =>
                                <React.Fragment key={x.documentId}>
                                    <Grid item xs={11}>

                                        <Accordion square expanded={expanded.has(x.projectId)} onChange={() => handleExpand(x.projectId)}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography>{x.title} ({x.year})</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul>
                                                        {
                                                            x.documents.map(d =>
                                                                <li key={d.documentId}>
                                                                    <a href={process.env.REACT_APP_API + "backend/sis/secure/file?reference=" + (d.reference || d.documentId)} target="_blank">{d.title}</a>
                                                                </li>)
                                                        }
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>


                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button onClick={() => deleteFunc(x.projectId)}><DeleteForeverIcon></DeleteForeverIcon></Button>
                                    </Grid>
                                </React.Fragment>
                            )}
                    </Grid>
                    {
                        documents.length > showCount && <Button onClick={() => setShowCount(showCount + step)}><MoreHorizIcon></MoreHorizIcon></Button>
                    }
                </Grid>
            </Grid>

        </div>

    );
}

export default ProjectList;