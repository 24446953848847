import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
//import Comm from '../comm/comm';
import DLG from './form/DLG';

const LazyImage = ({ id, open, onClose, title, }) => {

    let [loaded, setLoaded] = useState(false);
    let [url, setUrl] = useState(process.env.REACT_APP_API + "backend/sis/secure/file/preview?reference=" + id);

    // const arrayBufferToBase64 = (buffer) => {
    //     return btoa(String.fromCharCode(...new Uint8Array(buffer)));
    // }

    useEffect(() => {
        // if (open && !url) {
        //     Comm.Instance().get("backend/sis/secure/file/preview?reference=" + id, {
        //         responseType: 'arraybuffer'

        //     })
        //         .then(r => {
        //             console.log  ("img", r);
        //             const base64 = arrayBufferToBase64(r.data);

        //             const dataUrl = `data:image/png;base64,${base64}`;
        //             setUrl(dataUrl);
        //             setLoaded(true);

        //         })
        // }
        setUrl(process.env.REACT_APP_API + "backend/sis/secure/file/preview?reference=" + id);


    }, [id, open, url])




    return (
        <DLG open={open} onClose={onClose} title={title}>
            <div className="container">

                {
                    !loaded &&

                    <ClipLoader
                        size={150}
                        color={"#123abc"}
                        loading={true}
                    />
                }
                {                    

                    <img src={url} alt="Преглед" style={{ 'max-width': '100%' }} onLoad={()=>setLoaded(true)}></img>
                }
            </div>
        </DLG>
    )


}

export default LazyImage;