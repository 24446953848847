import React from 'react';
import { Button } from '@material-ui/core';

const IPPager = ({ totalPages, page, setPage }) => {
    const showPages = 10;

    const from = Math.max(1, page - parseInt(showPages / 2));
    const to = Math.min(totalPages, page + parseInt(showPages / 2));
    const parr = [];
    for (let i = from; i <= to; i++) {
        parr.push(i);
    }


    return (
        <>
            {page > 1 && <Button onClick={() => setPage(1)}>&lt;&lt;</Button>}
            {page > 1 && <Button onClick={() => setPage(page - 1)}>&lt;</Button>}
            {parr.map((item, index) =>
                // eslint-disable-next-line eqeqeq
                <Button onClick={() => setPage(item)} key={index} disabled={item == page}>{item}</Button>

            )}
            {page < totalPages && <Button onClick={() => setPage(page + 1)}>&gt;</Button>}
            {page < totalPages && <Button onClick={() => setPage(totalPages)}>&gt;&gt;</Button>}

        </>
    )

}

export default IPPager;