import React, { useState } from 'react';
import CB from '../controls/form/CB';

const IPCheckList = (props) => {

    const [val, setVal] = useState(props.obj.value);
    const click = (value, checked) => {
        let vals = val;


        if (checked) {
            vals.push(value);

        }
        else {
            vals = vals.filter(x => x !== value);
        }



        setVal([...vals]);
        props.setValue(props.obj.id, vals)
    }

    return (
        <div className="form-group col-md-12">
            <label>{props.obj.label}</label>

            {
                props.obj.items.map(i =>
                <>
            <CB checked={val && val.filter(x => x === i.value).length > 0} change={(e) => click(i.value, e.target.checked)} key={'c' + i.value} disabled={props.readonly} label={i.text} />
        </>
                    
                )
            }

        </div>

    );
}


export default IPCheckList;