import React from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon'
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DLG = (props) => {

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            fullScreen
            onClose={() => props.onClose(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Grid container spacing={3}>
                    <Grid item xs={11}>
                        {props.title}
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => props.onClose(false)} color="primary">
                            <Icon className="fa fa-close" />

                        </Button>
                    </Grid>
                </Grid>


            </DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>

        </Dialog>

    )
}

export default DLG;