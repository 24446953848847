import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const ProjectHeader = () => {
    return (

        <Grid container spacing={0}>
            <Grid item xs={2}>
                <Typography variant="h6" gutterBottom>Година</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" gutterBottom>Стар индекс</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>Име</Typography>
            </Grid>
            <Grid item xs={1}>
                
            </Grid>
            <Grid item xs={1}>
                
            </Grid>

        </Grid>
    )
}

export default ProjectHeader;