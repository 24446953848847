/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import Comm from '../comm/comm';
import IPGrid from '../controls/grid/IPGrid';
import DocumentHeader from '../controls/grid/DocumentHeader';
import DocumentRow from '../controls/grid/DocumentRow';


const DocumentSelected = (props) => {
    let [searchParams,] = useState({
        func: "/backend/sis/secure/file/selected",
        params: {}, 
        method:"GET"
    });
    let [refresh, setRefresh] = useState(new Date());







    

    const Delete = (id) => {
        Comm.Instance().delete("backend/sis/secure/file?reference=" + id).then(r => {
            setRefresh(new Date());
        });
    }











    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Избрани документи</h2>
            



            <Paper>
                <IPGrid searchParams={searchParams}
                    refresh={refresh}
                    header={() => <DocumentHeader></DocumentHeader>}
                    row={(item) => <DocumentRow item={item} deleteFunc={Delete}></DocumentRow>}
                    hidePageSize={true}>

                </IPGrid>
            </Paper>

        </div>
    );
}


export default DocumentSelected;