import React from 'react';
// components/SuccessSnackbar.js or whatever you wanna call it
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from '@material-ui/lab';
import { useRecoilValue, useRecoilState } from 'recoil';
import ToastMessageSelector from '../../selectors/ToastMessageSelector';
import ToastMessageAtom from '../../atoms/ToastMessageAtom';

const SuccessSnackbar = () => {
    const [, setSuccess] = useRecoilState(ToastMessageAtom);

    const message = useRecoilValue(ToastMessageSelector);
    const handleClose = () => {
        setSuccess("")
    }


    const severity = message.type;

    return (
        <Snackbar open={message !== ''} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert onClose={handleClose} severity={severity}>
                {
                    Array.isArray(message.message) ?
                        message.message.map(m => <p>{m}</p>) :
                        <p>{message.message}</p>

                }
            </Alert>
        </Snackbar>
    );
}

export default SuccessSnackbar;