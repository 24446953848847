/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import EkatteEdit from '../controls/EkatteEdit';
import FakeData from '../fakedata/FakeData'
import { Grid, Button } from '@material-ui/core';
import TB from '../controls/form/TB';
import DDL from '../controls/form/DDL';
import MultiTB from '../controls/form/MultiTB';
import { useRecoilState, } from 'recoil';
import ToastMessageAtom from '../atoms/ToastMessageAtom';
import DocumentList from '../controls/DocumentList';
import ProjectList from '../controls/ProjectList';
import ProtocolList from '../controls/ProtocolList';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Comm from '../comm/comm';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Coordinates from '../controls/Coordinates';


const Dossier = (props) => {
    let [id, setId] = useState(props.match.params.dossierId);
    let [dossier, setDossierState] = useState();
    let [categories, setCategories] = useState();
    let [classifications, setClassifications] = useState();
    let [page, setPage] = useState(1);
    let [history, setHistory] = useState();
    let [ro, setRO] = useState(false);

    let [showSave, setShowSave] = useState(false);


    let [selectedDocuments, setSelectedDocuments] = useState();

    const [, setSuccess] = useRecoilState(ToastMessageAtom);




    const setDossier = (obj) => {
        setDossierState(obj);
        setShowSave(true);
    }


    useEffect(() => {

        if (props.match.params.dossierId) {
            let getParams = "artifactId=" + props.match.params.dossierId;
            if (props.match.params.versionId) {
                getParams += "&versionId=" + props.match.params.versionId;
                setRO(true)
            }


            Comm.Instance().get("/backend/sis/secure/polymorphic/artifact/page/active?" + getParams)
                .then(r => {

                    let d = r.data.rows[0];
                    d.coordinates = d.coordinates || [{ lat: null, lng: null }];
                    d.classificationDocs = d.classificationDocs || [];
                    d.categoryDocs = d.categoryDocs || [];
                    d.otherDocuments = d.otherDocuments || [];
                    d.researchDocuments = d.researchDocuments || [];

                    setShowSave(false);
                    Comm.Instance().get("/backend/sis/secure/artifact?reference=" + props.match.params.dossierId)
                        .then(r2 => {
                            console.log("real dossier", d);
                            d.selected = r2.data.selected;
                            
                            setDossier(d);
                            setShowSave(false);
                        });

                });

            Comm.Instance().get("backend/sis/secure/polymorphic/artifact?artifactId=" + props.match.params.dossierId)
                .then(r => {
                    setHistory(r.data);
                });

        } else {
            setDossier({
                "name": "",
                "NKCType3d": "",
                "objectNumber": "",
                "ekatte": "0",
                "address": "",
                "cadastreData": "",
                "oldCadastreData": "",
                "protocols": [],
                "projects": [],
                "researchDocuments": [],
                "otherDocuments": [],
                "selected": false,
                "main": {},
                "coordinates": [],

            });
            setShowSave(false);


        }

        setCategories(FakeData.categories);
        //Comm.Get("categories", null, setCategories);
        setClassifications(FakeData.classifications);
        Comm.Instance().get("/backend/sis/secure/file/selected").then(r => setSelectedDocuments(r.data.rows));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.dossierId, props.match.params.versionId]);







    const setDossierData = (id, data) => {
        dossier[id] = data;
        setDossier({ ...dossier });
    }

    const setDossierMainData = (id, data) => {
        dossier.main[id] = data;
        dossier.main = { ...dossier.main };
        setDossier({ ...dossier });
    }

    const addCoordinates = () => {
        dossier.coordinates.push({ lat: null, lng: null });
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }

    const removeCoordinates = (index) => {
        dossier.coordinates.splice(index, 1);
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }

    const setCoordinateValue = (index, property, value) => {
        dossier.coordinates[index][property] = value;
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }

    const setEkatte = (ekatte) => {
        setDossierData('ekatte', ekatte);
    }






    const DeleteResearchDocument = (id) => {
        dossier.researchDocuments = dossier.researchDocuments.filter(x => x.documentId !== id);
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }

    const DeleteOtherDocument = (id) => {
        dossier.otherDocuments = dossier.otherDocuments.filter(x => x.documentId !== id);
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }

    const DeleteProtocol = (id) => {
        dossier.protocols = dossier.protocols.filter(x => x.protocolId !== id);
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }

    const DeleteProject = (id) => {
        dossier.projects = dossier.projects.filter(x => x.projectId !== id);
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }

    const AddResearchDoc = (doc) => {
        dossier.researchDocuments.push({ ...doc });
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }
    const AddOtherDoc = (doc) => {
        console.log(dossier);
        dossier.otherDocuments.push({ ...doc });
        setDossier(JSON.parse(JSON.stringify(dossier)));
    }


    const AddProtocol = (id) => {

        Comm.Instance().get(`/backend/sis/secure/minutes?reference=${id}`)
            .then(r => {
                let proto = {
                    protocolId: r.data.id,
                    title: r.data.name
                };
                Comm.Instance().post("/backend/sis/secure/file/search", { minutesReference: id })
                    .then(r2 => {
                        proto.documents = r2.data.rows.map(item => { return { documentId: item.documentId, title: item.name || item["file-name"], reference: item.documentId } });
                        dossier.protocols.push(proto);
                        setDossier(JSON.parse(JSON.stringify(dossier)));
                    });

            })


    }
    const AddProject = (id) => {
        Comm.Instance().get(`/backend/sis/secure/project?reference=${id}`)
            .then(r => {
                let proj = {
                    projectId: r.data.id,
                    title: r.data.name
                };
                Comm.Instance().post("/backend/sis/secure/file/search", { projectReference: id })
                    .then(r2 => {
                        proj.documents = r2.data.rows.map(item => { return { documentId: item.documentId, title: item.name || item["file-name"], reference: item.documentId } });
                        dossier.projects.push(proj);
                        setDossier(JSON.parse(JSON.stringify(dossier)));
                    });

            })
    }



    const Save = () => {
        Comm.Instance().post(`/backend/sis/secure/polymorphic/artifact/page`, dossier).then(r => {
            setId(r.data.id)
        });
        setShowSave(false);
        setSuccess({ message: "Данните бяха успешно записани", type: "success" })

    }



    const delClassificationDoc = (index) => {
        dossier.classificationDocs.splice(index, 1);
        dossier = JSON.parse(JSON.stringify(dossier));
        setDossier({ ...dossier });
        setShowSave(true);
    }

    const delCategoryDoc = (index) => {
        dossier.categoryDocs.splice(index, 1);
        dossier = JSON.parse(JSON.stringify(dossier));
        setDossier({ ...dossier });
        setShowSave(true);
    }

    const addClassificationDocument = (id) => {
        if (id === 0) {
            return;
        }

        let doc = selectedDocuments.find(x => x.documentId === id);
        dossier.classificationDocs.push({ ...doc });
        setDossier(JSON.parse(JSON.stringify(dossier)));
        setShowSave(true);
    }
    const addCategoryDocument = (id) => {
        if (id === 0) {
            return;
        }

        console.log("selectedDocuments", selectedDocuments);

        let doc = selectedDocuments.find(x => x.documentId === id);
        dossier.categoryDocs.push({ ...doc });
        setDossier(JSON.parse(JSON.stringify(dossier)));
        setShowSave(true);
    }



    const changeSelection = () => {
        dossier.selected = !dossier.selected;
        setDossierState({ ...dossier });
        Comm.Instance().post("/backend/sis/secure/artifact/selected", {
            "artifactId": id,
            "selected": dossier.selected
        })

    }

    const tabClass = (id) => {
        return page === id ? "btn btn-info" : "btn btn-secondary";
    }


    if (!dossier || !categories || !classifications || !selectedDocuments)
        return (
            <div className="container">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={true}
                />
            </div>
        );




    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Досие на недвижима културна ценност  - {dossier.name}</h2>
            <div className="tabcontainer">
                <button className={tabClass(1)} onClick={() => setPage(1)}>Основни данни</button>&nbsp;
                <button className={tabClass(2)} onClick={() => setPage(2)}>Документи за проучване</button>&nbsp;
                <button className={tabClass(3)} onClick={() => setPage(3)}>Други документи</button>&nbsp;
                <button className={tabClass(4)} onClick={() => setPage(4)}>Протоколи</button>&nbsp;
                <button className={tabClass(5)} onClick={() => setPage(5)}>Проекти</button>&nbsp;
                <button className={tabClass(6)} onClick={() => setPage(6)}>История</button>
            </div>


            {
                page === 1 &&
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <TB label="Наименование" value={dossier.name} change={(e) => setDossierData('name', e)} readonly={ro} />
                    </Grid>
                    <Grid item xs={4}>
                        <TB label="Пространствена структура" value={dossier.NKCType3d} change={(e) => setDossierData('NKCType3d', e)} readonly={ro} />
                    </Grid>

                    <Grid item xs={2}>
                        <TB label="Номер на обекта" value={dossier.objectNumber} change={(v) => setDossierData("objectNumber", v)} readonly={ro} />
                    </Grid>
                    <Grid item xs={2}>
                        <TB label="Стар индекс" value={dossier.registryIndexOld} change={(v) => setDossierData("registryIndexOld", v)} readonly={ro} />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <h5>Населено място по екатте</h5>
                        <EkatteEdit ekatteId={dossier.ekatte} setValue={setEkatte} readonly={false} />
                    </Grid>
                    <Grid item xs={12}>
                        <h5>Адресни данни</h5>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TB label="Населено място" value={dossier.main.habitat} change={(e) => setDossierMainData('habitat', e)} readonly={ro} />
                            </Grid>
                            <Grid item xs={6}>
                                <TB label="Община" value={dossier.main.municipality} change={(e) => setDossierMainData('municipality', e)} readonly={ro} />
                            </Grid>
                            <Grid item xs={6}>
                                <TB label="Адрес" value={dossier.address} change={(e) => setDossierData('address', e)} readonly={ro} />
                            </Grid>
                            <Grid item xs={6}>
                                <TB label="Местност" value={dossier.main.location} change={(e) => setDossierMainData('location', e)} readonly={ro} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={6}>
                        <TB label="Кадастрални данни" value={dossier.cadastreData} change={(e) => setDossierData('cadastreData', e)} readonly={ro} />
                    </Grid>

                    <Grid item xs={6}>
                        <TB label="Стари кадастрални данни" value={dossier.oldCadastreData} change={(e) => setDossierData('oldCadastreData', e)} readonly={ro} />
                    </Grid>
                    <Grid item xs={12}>
                        <Coordinates coordinates={dossier.coordinates} addCoordinates={addCoordinates} readOnly={ro} removeCoordinates={removeCoordinates} setCoordinateValue={setCoordinateValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <h5>Класификация, категоризация и режими на опазване</h5>
                    </Grid>
                    <Grid item xs={6}>
                        {/* <DDL label="Класификация"
                            value={dossier.classificationId}
                            change={(e) => setDossierData('classificationId', e)}
                            items={classifications}
                            idfield="classificationId"
                            labelfield="title"
                            readonly={ro}
                        /> */}
                        <TB label="Чл 47" value={dossier.main.art47Type} change={(e) => setDossierMainData('art47Type', e)} readonly={ro} />
                    </Grid>
                    <Grid item xs={6}>
                        {
                            dossier.classificationDocs &&
                            <ul>
                                {
                                    dossier.classificationDocs.map((item, index) =>
                                        <li>
                                            <a href={process.env.REACT_APP_API + "backend/sis/secure/file?reference=" + (item.id || item.documentId)} target="_blank">{item.name || item.title}</a>
                                            <Button onClick={() => delClassificationDoc(index)}><DeleteForeverIcon></DeleteForeverIcon></Button>
                                        </li>
                                    )
                                }
                            </ul>

                        }
                        <DDL label="Добави документ"
                            value={0}
                            change={(e) => addClassificationDocument(e)}
                            items={selectedDocuments}
                            idfield="documentId"
                            labelfield="name"
                            readonly={ro}
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <TB label="Чл 48" value={dossier.main.art48Type} change={(e) => setDossierMainData('art48Type', e)} readonly={ro} />
                        {/* 
                        <DDL label="Категория"
                            value={dossier.categoryId}
                            change={(e) => setDossierData('categoryId', e)}
                            items={categories}
                            idfield="categoryId"
                            labelfield="title"
                            readonly={ro}
                        /> */}
                    </Grid>
                    <Grid item xs={6}>

                        {
                            dossier.categoryDocs &&
                            <ul>
                                {
                                    dossier.categoryDocs.map((item, index) =>
                                        <li>
                                            <a href={process.env.REACT_APP_API + "backend/sis/secure/file?reference=" + (item.id || item.documentId)} target="_blank">{item.name || item.title}</a>
                                            <Button onClick={() => delCategoryDoc(index)}><DeleteForeverIcon></DeleteForeverIcon></Button>
                                        </li>
                                    )
                                }
                            </ul>

                        }
                        <DDL label="Добави документ"
                            value={0}
                            change={(e) => addCategoryDocument(e)}
                            items={selectedDocuments}
                            idfield="documentId"
                            labelfield="name"
                            readonly={ro}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiTB label="Режим на опазване"
                            value={dossier.regime}
                            change={(e) => setDossierData('regime', e)}
                            readonly={ro}
                        />
                    </Grid>
                </Grid>


            }
            {
                page === 2 &&

                <DocumentList documents={dossier.researchDocuments} deleteFunc={DeleteResearchDocument} addFunc={(doc) => AddResearchDoc(doc)} readonly={ro}></DocumentList>

            }
            {
                page === 3 &&


                <DocumentList documents={dossier.otherDocuments} deleteFunc={DeleteOtherDocument} addFunc={(doc) => AddOtherDoc(doc)} readonly={ro}></DocumentList>

            }
            {
                page === 4 &&


                <ProtocolList deleteFunc={DeleteProtocol} addFunc={(id) => AddProtocol(id)} readonly={ro} protocols={dossier.protocols}></ProtocolList>
            }
            {
                page === 5 &&

                <ProjectList deleteFunc={DeleteProject} addFunc={(id) => AddProject(id)} readonly={ro} projects={dossier.projects}></ProjectList>


            }
            {
                page === 6 &&


                history &&
                history.rows.map(item => <p><Link to={`/dossier/${item.subjectReference}/${item.subjectId}`}>{moment(item.createdOn).format("DD.MM.YYYY hh:mm")} промяна от {item.createdBy}</Link></p>)
            }


            <Grid container spacing={3}>
                <Grid item xs={3}>
                    {showSave && <Button onClick={() => Save()} color="primary" variant="contained">Запис</Button>}
                </Grid>
                <Grid item xs={9}></Grid>
                {
                    id &&
                    <Grid item xs={3}>
                        {!dossier.selected && <Button onClick={() => changeSelection()} ><CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>Избор</Button>}
                        {dossier.selected && <Button onClick={() => changeSelection()} ><CheckBoxIcon></CheckBoxIcon>Премахване от избрани</Button>}
                    </Grid>
                }
            </Grid>




        </div >

    );
}


export default Dossier;