import { Checkbox } from '@material-ui/core';
import React  from 'react';

const CB=(props)=>{

    return <><Checkbox checked={props.checked} onChange={(e) => props.change(e.target.checked)} />{ props.label }</>
    
}

export default CB;
