import React from 'react';
import DateTB from '../controls/form/DateTB';


const IPDate = (props) => {
    


    
    return (
            <DateTB value={props.obj.value} change={(e) => props.setValue(props.obj.id, e.target.value)} label={props.obj.label} type="date"/>

    );
}


export default IPDate;