import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Comm from '../../comm/comm';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment';
import LazyImage from '../LazyImage';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const DocumentRow = ({ item, deleteFunc }) => {
    let [selected, setSelected] = useState(item.selected);
    let [prev, setPrev] = useState(false);
    let history = useHistory();

    const changeSelection = (id) => {
        Comm.Instance().post("/backend/sis/secure/file/selected", {
            "documentId": id,
            "selected": !selected
        });
        setSelected(!selected);


    }

    const displayTypes = (item) => {
        let arr = [];
        
        if (item.related.project) {
            item.related.project.forEach(x => arr.push({
                url: "Project/" + x,
                title: "Проект"
            }));
        }
        if (item.related.minutes){
            item.related.minutes.forEach(x=>arr.push({
                url: "Protocol/"+x,
                title: "Протокол"
            }));
        }
        if (item.related.artifact) {
            item.related.artifact.forEach(x => arr.push({
                url: "Dossier/" + x,
                title: "Досие"
            }));
        }
        


        return (
            <>
                {arr.map(x => <p><Button variant="text" onClick={() => history.push(x.url)}>{x.title}</Button></p>)}
            </>
        );
       
    }

    return (

        <Grid container spacing={0}>
            <Grid item xs={2}>
                {item.date && moment(new Date(item.date)).format("DD.MM.YYYY")}
            </Grid>
            <Grid item xs={3}>{displayTypes(item)}</Grid>
            <Grid item xs={4}>
                <Link to={`Document/${item.documentId}`}>
                    <Typography align="left">
                        {item.title || item["file-name"]}
                    </Typography>
                </Link>
                {item.aka && item.aka.map((i, ix) => <Typography variant="subtitle2" key={ix}>{i}</Typography>)}
            </Grid>
            <Grid item xs={1}>
                {!selected && <Button onClick={() => changeSelection(item.documentId)} ><CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon></Button>}
                {selected && <Button onClick={() => changeSelection(item.documentId)} ><CheckBoxIcon></CheckBoxIcon></Button>}
            </Grid>
            <Grid item xs={2}>
                <LazyImage id={item.documentId} open={prev} onClose={() => setPrev(false)} title={item.title || item["file-name"]}></LazyImage>
                <Button onClick={() => setPrev(true)}><ZoomInIcon></ZoomInIcon></Button>
                <Button onClick={() => deleteFunc(item.documentId)}><DeleteForeverIcon></DeleteForeverIcon></Button>
            </Grid>

        </Grid>
    )
}

export default DocumentRow;