import React from 'react';
import TB from '../controls/form/TB';


const IPEmail = (props) => {

    return (
        <TB value={props.obj.value} change={(e) => props.setValue(props.obj.id, e.target.value)} label={props.obj.label} type="email" />

    );
}


export default IPEmail;