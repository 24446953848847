import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import Comm from '../comm/comm';
import { Grid } from '@material-ui/core';
import DDL from './form/DDL';

const ModalAttachToDossier = ({ linkType, closeFunc, visible }) => {
    let [selDossier, setSelDossier] = useState(0);
    let [selDossierLinkType, setDossierLinkType] = useState(0);
    let [dossiers, setDossiers] = useState([]);

    const dossierPlaces = [
        { id: 1, name: "Документи за категоризация" },
        { id: 2, name: "Документи за класификация" },
        { id: 3, name: "Документи за проучване" },
        { id: 4, name: "Други документи" },
    ]




    const fetchData = async () => {
        Comm.Instance().get("/backend/sis/secure/artifact/selected")
            .then(r => {
                for (let item of r.data.rows) {
                    item.name = item.main.name;
                }
                setDossiers(r.data.rows);

            });

    };


    useEffect(() => {

        fetchData();
    }, []);



    const canShowMove = () => {
        return selDossier !== 0 && (!linkType || selDossierLinkType !== 0);
    }

    const move = () => {

        closeFunc(selDossier, selDossierLinkType);

    }

    return (
        <Dialog header="Моля изберете досие" visible={visible} style={{ width: '50vw' }} footer="" onHide={() => closeFunc(null)}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <DDL label="Досие"
                        value={selDossier}
                        change={(e) => setSelDossier(e)}
                        items={dossiers}
                        idfield="id"
                        labelfield="name"
                        readonly={false}
                    />
                </Grid>
                {
                    linkType&&
                    <Grid item xs={6}>
                        <DDL label="Част"
                            value={selDossierLinkType}
                            change={(e) => setDossierLinkType(e)}
                            items={dossierPlaces}
                            idfield="id"
                            labelfield="name"
                            readonly={false}
                        />
                    </Grid>
                }
            </Grid>

            {canShowMove() && <button className="btn btn-primary" onClick={() => move()}>Прикачване</button>}

        </Dialog>

    );
}


export default ModalAttachToDossier;

