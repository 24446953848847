import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const JournalRow = ({ item }) => {


    const showStatus = (status) => {
        switch (status) {
            case "A": return "Active";
            case "H": return "History";
            case "D": return "Deleted";
            default: return "";
        }
    }

    const showSubject = (subject) => {
        switch (subject) {
            case "ARTIFACT": return "Досие";
            case "PROJECT": return "Проект";
            case "MINUTES": return "Протокол";
            case "DOCUMENT": return "Документ";
            case "CLASSIFIER": return "Класификатор";
            case "DOCUMENT_CLASSIFIER": return "Документ - класификатор";
            case "DOCUMENT_CORRESPONDENCE": return "Документ - преписка";
            case "MINUTES_DOCUMENT": return "Документ - протокол";
            case "PROJECT_DOCUMENT": return "Документ - проект";
            case "ARTIFACT_DOCUMENT": return "Документ - досие";
            case "PROJECT_ENTRY": return "Досие - проект";
            case "MINUTES_ENTRY": return "Досие - протокол";

            default: return "";
        }
    }
    const showAction = (action) => {
        switch (action) {
            case "C": return "Create";
            case "U": return "Update";
            case "H": return "move to History";
            case "D": return "Delete";
            default: return "";
        }
    }

    return (

        <Grid container spacing={0}>
            <Grid item xs={2}>
                {moment(item.createdOn).format("DD.MM.YYYY hh:mm")}
            </Grid>
            <Grid item xs={6}>

                <small>{item.subjectReference} {item.subjectId}</small>
            </Grid>
            <Grid item xs={2}>
                {item.createdBy}
            </Grid>
            <Grid item xs={2}>
                {showStatus(item.status)}
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor': '#b9d5ff91' }}>
                {showSubject(item.subject)}
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor': '#b9d5ff91' }}>
                {showAction(item.userAction)}
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor': '#b9d5ff91' }}>
                {item.remote && item.remote.ip}
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor':'#b9d5ff91'}}>
                {item.remote && item.remote.userAgent}
            </Grid>

        </Grid>
    )
}

export default JournalRow;