/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import moment from 'moment';
import { Button, Grid } from '@material-ui/core';
import TB from '../controls/form/TB';
import DDL from '../controls/form/DDL';
import { useRecoilState } from 'recoil';
import ToastMessageAtom from '../atoms/ToastMessageAtom';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateTB from '../controls/form/DateTB';
import MultiTB from '../controls/form/MultiTB';
import Comm from '../comm/comm';
import { Link } from 'react-router-dom';
import ModalAttachToDossier from '../controls/ModalAttachToDossier';

const Document = (props) => {
    let [document, setDocumentState] = useState();
    let [page, setPage] = useState(1);
    let [history, setHistory] = useState();
    let [showSave, setShowSave] = useState(false);
    let [docTypes, setDocTypes] = useState([]);
    let [ro, setRO] = useState(false);
    let [showAttachDialog, setShowAttachDialog] = useState(false)
    const [, setSuccess] = useRecoilState(ToastMessageAtom);
    let [newValue, setNewValue] = useState()



    const setDocument = (obj) => {
        setDocumentState(obj);

    }


    useEffect(() => {

        const fetchData = async () => {


            Comm.Instance().get("backend/sis/secure/file/classifier/doctype").then(r => {
                setDocTypes(r.data.rows);
            });

            if (props.match.params.documentId) {
                let getParams = "reference=" + props.match.params.documentId;
                if (props.match.params.versionId) {
                    getParams += "&versionId=" + props.match.params.versionId;
                    setRO(true)
                }
                Comm.Instance().get("backend/sis/secure/file/metadata?" + getParams)
                    .then(r => {
                        r.data.date = moment(r.data.date).format("YYYY-MM-DD");
                        setDocument(r.data);
                    });
                Comm.Instance().get("backend/sis/secure/polymorphic/file?documentId=" + props.match.params.documentId)
                    .then(r => {
                        setHistory(r.data);
                    });
            }
            else {
                setDocument({
                    "documentId": null,
                    "date": "",
                    "selected": false,
                    "application-reference": "",
                    "file-name": null,
                })
            }


            setShowSave(false);



        };
        fetchData();
    }, [props.match.params.documentId, props.match.params.versionId]);



    const Validate = () => {
        let result = [];
        if ((document.title || "") === "") {
            result.push("Моля, въведете име");
        }
        if (!props.match.params.documentId) {
            var imagefile = window.document.getElementById('fuUpload');
            if (imagefile.files.length === 0) {
                result.push("Моля, изберете файл");
            }
        }


        return result;
    }

    const Save = () => {
        const validate = Validate();
        if (validate.length === 0) {
            if (props.match.params.documentId) {
                Comm.Instance().post("backend/sis/secure/file/metadata", document).then(r => {
                    setDocument({ ...document });
                    setShowSave(false);
                    setSuccess({ message: "Данните бяха успешно записани", type: "success" });
                });
            }
            else {
                var formData = new FormData();
                var imagefile = window.document.getElementById('fuUpload');
                formData.append("file", imagefile.files[0]);
                formData.append("name", document.title);
                formData.append("reference", "");

                Comm.Instance().post("/backend/sis/secure/file/correspondence", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(r => {
                    console.log("RESEIL", r);
                });


            }

        }
        else {
            setSuccess({ message: validate, type: "error" })
        }
    }


    const setData = (id, value) => {


        document[id] = value;
        setDocument({ ...document });
        setShowSave(true);

    };




    const changeSelection = () => {
        document.selected = !document.selected;
        setDocument({ ...document });
        Comm.Instance().post("/backend/sis/secure/file/selected", {
            "documentId": props.match.params.documentId,
            "selected": document.selected
        })

    }


    const attachToDossier = (dossierId, linkType) => {
        Comm.Instance().post("/backend/sis/secure/artifact/file", {
            documentId: props.match.params.documentId,
            artifactId: dossierId,
            "isArtifactCategorization": linkType === 1,
            "isArtifactClassification": linkType === 2,
            "isArtifactMisc": linkType === 4,
            "isArtifactStudy": linkType === 3,

        })


        setShowAttachDialog(false);
    }

    const changeVisibility = () => {
        if (document.downloadable) {
            document.downloadable = false;
        } else {
            document.downloadable = true;
        }

        setDocument({ ...document });
        Comm.Instance().post("/backend/sis/secure/file/public", {
            "documentId": props.match.params.documentId,
            "downloadable": document.downloadable
        })

    }

    const tabClass = (id) => {
        return page === id ? "btn btn-info" : "btn btn-secondary";
    }


    if (!document)
        return (
            <div className="container">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={true}
                />
            </div>
        );


    return (

        <div className="container">
            <div className="tabcontainer">
                <h2 className="appeal-title mb-5">Документ  - {document.title}</h2>
                <button className={tabClass(1)} onClick={() => setPage(1)} type="button">Основни данни</button>&nbsp;
                <button className={tabClass(2)} onClick={() => setPage(2)} type="button">История</button>
            </div>


            <div className="container">
                {
                    page === 1 &&
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <TB label="Описание/тип на документа" value={document.title} change={(v) => setData("title", v)} readonly={ro} />
                        </Grid>

                        <Grid item xs={3}>
                            <DDL label="Класификация"
                                value={document["document-type-id"]}
                                change={(e) => setData('document-type-id', e)}
                                items={docTypes}
                                idfield="docTypeId"
                                labelfield="name"
                                readonly={ro}
                            />

                        </Grid>
                        <Grid item xs={3}>
                            <DateTB value={document.date} change={(e) => setData("date", e.target.value)} label="Дата" readonly={true} />
                        </Grid>

                        <Grid item xs="3">
                            {
                                document["file-size"] > 0 &&
                                <a href={process.env.REACT_APP_API + "backend/sis/secure/file?reference=" + props.match.params.documentId} target="_blank">Свали</a>
                            }

                            {
                                !props.match.params.documentId &&
                                <input type="file" id="fuUpload" value={newValue} onChange={(e) => { setNewValue(e.target.value); setShowSave(true); }}></input>
                            }

                        </Grid>
                        <Grid item xs="3">
                            {/* <Button color="primary" onClick={() => setShowOcr(true)}><VisibilityIcon></VisibilityIcon></Button> */}
                            <TB label="Номер на преписка" value={document["application-reference"]} change={(v) => setData("application-reference", v)} readonly={true} />
                        </Grid>
                        <Grid item xs="3">
                            <MultiTB label="Описание" value={document["description"]} change={(v) => setData("description", v)} readonly={true} />
                        </Grid>
                    </Grid>
                }
                {
                    page === 2 &&

                    history &&
                    history.rows.map(item => <p><Link to={`/Document/${item.subjectReference}/${item.subjectId}`}>{moment(item.createdOn).format("DD.MM.YYYY hh:mm")} промяна от {item.createdBy}</Link></p>)


                }


                <div className="form-group col-md-3">
                    {showSave && !ro && <Button onClick={() => Save()} color="primary" variant="contained">Запис</Button>}&nbsp;
                    {
                        props.match.params.documentId && <Button onClick={() => setShowAttachDialog(true)} color="primary" variant="contained">Прикачи към досие</Button>
                    }

                </div>
                {
                    props.match.params.documentId &&
                    <div className="form-group col-md-5">
                        {!document.selected && <Button onClick={() => changeSelection()} ><CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>Избор</Button>}
                        {document.selected && <Button onClick={() => changeSelection()} ><CheckBoxIcon></CheckBoxIcon>Премахване от избрани</Button>}

                    </div>
                }
                {
                    props.match.params.documentId &&
                    <div className="form-group col-md-5">
                        {!document.downloadable && <Button onClick={() => changeVisibility()} ><CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>Направи сваляем</Button>}
                        {document.downloadable && <Button onClick={() => changeVisibility()} ><CheckBoxIcon></CheckBoxIcon>Направи несваляем</Button>}
                    </div>
                }
            </div>
            <ModalAttachToDossier linkType={true} closeFunc={attachToDossier} visible={showAttachDialog}></ModalAttachToDossier>
        </div>
    );
}


export default Document;