import React, { useEffect, useState } from 'react';
import keycloak from '../tools/keycloak';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const NavMenu = (props) => {

    let [uname, setuname] = useState("");
    const [open, setOpen] = useState();

    const history = useHistory();

    if (open) {
        props.handleDrawerClose();
        let url = open;
        setOpen(undefined);
        history.push(url);
    }



    const logout = () => {
        keycloak.logout();
    }

    useEffect(() => {
        keycloak.loadUserInfo().then(r => setuname(r.name));
    }, [])


    return (

        <div className="service-nav">
            <div>
                <IconButton onClick={props.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <h5> {uname}</h5>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Досие</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <p><Button onClick={() => setOpen('/DossierSearch')} >Търсене</Button></p>
                    <p><Button onClick={() => setOpen('/DossierSelected')} >Избрани</Button></p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Протоколи</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <p><Button onClick={() => setOpen('/ProtocolSearch')}>Търсене</Button></p>
                    <p><Button onClick={() => setOpen('/ProtocolSelected')} >Избрани</Button></p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Проекти</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <p><Button onClick={() => setOpen('/ProjectSearch')}>Търсене</Button></p>
                    <p><Button onClick={() => setOpen('/ProjectSelected')} >Избрани</Button></p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Документи</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <p><Button onClick={() => setOpen('/DocumentSearch')}>Търсене</Button></p>
                    <p><Button onClick={() => setOpen('/DocumentSelected')} >Избрани</Button></p>
                </AccordionDetails>

            </Accordion>
            <Button onClick={() => setOpen('/NASearch')}>Нормативни актове</Button>
            <Button onClick={() => setOpen('/Journal')}>Журнал</Button>
            <Button onClick={() => window.open("https://ais-ninkn.eu/eDelo2","_tab")}>АИС</Button>
            {/* <Button onClick={() => setOpen('/LogSearch')}>Журнал</Button>
            <Button onClick={() => setOpen('/DataDesigner')}>Дизайнер</Button>             */}
            <button className="btn btn-link" onClick={() => logout()}>Изход</button>
        </div>
    );

}

export default NavMenu;
