import React from 'react';
import CB from '../controls/form/CB';


const IPCheck = (props) => {


    return (
            <CB checked={props.obj.value} change={(e) => props.setValue(props.obj.id, e.target.checked)} label={props.obj.label} />
        

    );
}


export default IPCheck;