/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import Comm from '../comm/comm';
import IPGrid from '../controls/grid/IPGrid';
import DossierHeader from '../controls/grid/DossierHeader';
import DossierRow from '../controls/grid/DossierRow';


const DossierSelected = (props) => {
    let [searchParams,] = useState({
        func: "/backend/sis/secure/artifact/selected",
        params: {}, 
        method:"GET"
    });
    let [refresh, setRefresh] = useState(new Date());







    

    const Delete = (id) => {
        Comm.Instance().delete("backend/sis/secure/artifact?reference=" + id).then(r => {
            setRefresh(new Date());
        });
    }











    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Избрани досиета</h2>
            



            <Paper>
                <IPGrid searchParams={searchParams}
                    refresh={refresh}
                    header={() => <DossierHeader></DossierHeader>}
                    row={(item) => <DossierRow item={item} deleteFunc={Delete}></DossierRow>}
                    hidePageSize={true}>

                </IPGrid>
            </Paper>

        </div>
    );
}


export default DossierSelected;