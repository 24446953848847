import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';

const ModalText = (props) => {
    let [val, setVal] = useState(props.value);
    return (
        <Dialog header="Въвеждане на стойност" visible={props.display} style={{ width: '50vw' }} footer="" onHide={() => props.closeFunc()}>
            <p>
                <label>{props.title}</label><textarea rows="6" className="form-control" type={props.dataType} onChange={(e) => setVal(e.target.value)} value={val}></textarea>
            </p>
            <button className="btn btn-primary" onClick={() => props.saveFunc(val)}>Запис</button>
        </Dialog>

    );
}


export default ModalText;

