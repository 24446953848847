/* eslint-disable eqeqeq */
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import DDL from './form/DDL';
import Comm from '../comm/comm';

const EkatteEdit = ({ ekatteId, setValue, readonly, setValueId }) => {



    const [items, setItems] = useState(null);
    const [ekatteSruct, setEkatteStruct] = useState(null);


    useEffect(() => {
        Comm.Instance().get("backend/secure/ekatte-tree/1")
            .then(r => {
                setItems([r.data]);
            });

        Comm.Instance().get(`backend/secure/ekatte/${ekatteId}`)
            .then(r2 => {
                setEkatteStruct(r2.data)
            });

    }, [ekatteId])



    const ShouldDisplay = (index) => {
        return (index === 0) || (ekatteSruct[index - 1].value != "0")
    };


    const GetItems = (index) => {
        var currentItems = items;
        var i = 0
        while (i < index) {
            let key = ekatteSruct[i].value
            if (key == 0) return null;
            currentItems = currentItems.filter(x => x.key === key)[0].children;
            i++;
        }
        return currentItems;
    };

    const SetLevelValue = (index, value) => {
        ekatteSruct[index].value = value;
        for (var i = index + 1; i < ekatteSruct.length; i++) {
            ekatteSruct[i].value = "0";
        }
        setEkatteStruct([...ekatteSruct]);
        console.log("SetLevelValue", value);
        if (value == "0")
            return;
        if (setValueId) {
            setValue(setValueId, value)
        }
        else {

            setValue(value)
        }

    };



    if (!ekatteSruct || !items) {
        return (
            <div className="form-group col-md-12">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={!ekatteSruct || !items}
                />
            </div>
        )
    }

    


    return (
        <Grid container spacing={3}>


            {
                ekatteSruct.map((item, index) =>

                    ShouldDisplay(index) ?
                        <Grid item xs={3} key={index}>
                            <DDL label={item.name} value={ekatteSruct[index].value} change={e => SetLevelValue(index, e)}
                                items={GetItems(index)}
                                idfield="key"
                                labelfield="label"
                                disabled={readonly}></DDL>
                        </Grid>
                        : null

                )
            }


        </Grid>


    );
}


export default EkatteEdit;