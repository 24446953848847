const FakeData = {
    selectedDocuments: [
        { documentId: 100, file: "-sof028-317-267-31.pdf", date: "1996-04-08", title: "документ 1", docTypeId: 5 },
        { documentId: 200, file: "-sof028-317-267-33.pdf", date: "1996-04-08", title: "документ 2", docTypeId: 5 },
        { documentId: 3300, file: "-sof028-317-267-39.pdf", date: "1995-07-14", title: "документ 3", docTypeId: 5 }
    ],
    docTypes: [
        { docTypeId: 1, name: "Декларационна / информационна карта" },
        { docTypeId: 2, name: "Фиш" },
        { docTypeId: 3, name: "Преписка" },
        { docTypeId: 4, name: "Фотодокументация" },
        { docTypeId: 5, name: "Протокол" },
        { docTypeId: 6, name: "Доклад" },
        { docTypeId: 7, name: "Становище" },
        { docTypeId: 8, name: "Експертиза" },
        { docTypeId: 9, name: "Акт за собственост / Нотариален акт " },
        { docTypeId: 10, name: "Пълномощно" },
        { docTypeId: 11, name: "Чертежи " },
        { docTypeId: 12, name: "Други документи за обекта" },
        { docTypeId: 13, name: "Научно - мотивирано предложение" },
        { docTypeId: 's', name: "Преписка за съгласуване" },
        { docTypeId: 't', name: "Текстова част" },
        { docTypeId: 'g', name: "Графична част" },
        { docTypeId: 'a', name: "Акт за собственост" },
        { docTypeId: 'b', name: "Булстат" },
        { docTypeId: 'd', name: "Договор" },
        { docTypeId: 'p', name: "Пълномощно" },
        { docTypeId: 'op', name: "Опорен план" }
    ],
    protoDocTypes: [
        { docTypeId: 1, name: "Протокол" },
        { docTypeId: 2, name: "Стенограма" },
        { docTypeId: 3, name: "Бележки" }
    ],
    projectDocTypes: [
        { docTypeId: 1, name: "Протокол" },
        { docTypeId: 2, name: "Стенограма" },
        { docTypeId: 3, name: "Бележки" }
    ],

    ekatte: [
        {
            "key": "1",
            "label": "България",
            "children": [
                {
                    "key": "BLG",
                    "label": "Благоевград",
                    "children": [{
                        "key": "BLG01",
                        "label": "Банско",
                        "children": [{
                            "key": "02676", "label": "гр.Банско", "children": null
                        }, { "key": "17381", "label": "с.Гостун", "children": null }]




                    }]
                },
                {
                    "key": "SOF",
                    "label": "София - град",
                    "children": [{
                        "key": "SOF46",
                        "label": "Столична",
                        "children": [{
                            "key": "68134", "label": "гр. София", "children": null
                        }]



                    }]

                }
            ],
        }
    ],

    ekatteStruct: [
        {
            value: '1',
            ekatteTypeId: 1,
            name: "Държава"
        },
        {
            value: 'SOF',
            ekatteTypeId: 2,
            name: "Област"
        },
        {
            value: 'SOF46',
            ekatteTypeId: 3,
            name: "Община"
        },
        {
            value: '68134',
            ekatteTypeId: 4,
            name: "Населено място"
        }
    ],

    searchResult: [
        {
            dossierId: 123213,
            name: "Централни хали",
            number: "317-267",
            selected: true
        }
    ],
    protocol: {
        name: "Протокол от заседание на експертен съвет",
        numbers: ["111-222-333", "111-222-444"],
        history: [
            { historyId: 1, onDate: "2020-09-25 12:42:38.17322", username: "Александър Константинов" },
            { historyId: 2, onDate: "2012-03-22 12:42:38.17322", username: "Александър Константинов" },
            { historyId: 3, onDate: "2011-07-25 12:42:38.17322", username: "Александър Константинов" },

        ],
        additionalData: [
            {
                "id": "date",
                "type": "date",
                "label": "Дата на провеждане",
                "value": "2001-12-11"
            },
            {
                "id": "description",
                "type": "text",
                "label": "Описание",
                "value": "Описание"
            }
        ],
        documents: {
            count: 100,
            rows: [
                {
                    id: 1,
                    name: "Протокол",
                    onDate: "2020-09-25 12:42:38.17322",
                    documentTypeId: 1,
                    docId: 12
                },
                {
                    id: 2,
                    name: "Стенограма",
                    onDate: "2020-09-25 12:42:38.17322",
                    documentTypeId: 2,
                    docId: 13
                },
                {
                    id: 3,
                    name: "Бележки",
                    onDate: "2020-09-25 12:42:38.17322",
                    documentTypeId: 3,
                    docId: 15
                },

            ]
        }

    },
    project: {
        name: "Проект за реконструкция",
        numbers: ["111-222-333", "111-222-444"],
        history: [
            { historyId: 1, onDate: "2020-09-25 12:42:38.17322", username: "Александър Константинов" },
            { historyId: 2, onDate: "2012-03-22 12:42:38.17322", username: "Александър Константинов" },
            { historyId: 3, onDate: "2011-07-25 12:42:38.17322", username: "Александър Константинов" },

        ],
        additionalData: [
            {
                "id": "date",
                "type": "date",
                "label": "Дата на изготвяне",
                "format": "date",
                "value": "2001-12-11"
            },
            {
                "id": "description",
                "type": "text",
                "label": "Описание",
                "value": "Описание"
            }
        ],
        documents: {
            count: 100,
            rows: [
                {
                    id: 1,
                    name: "Проект",
                    onDate: "2020-09-25 12:42:38.17322",
                    documentTypeId: 1,
                    docId: 12
                },
                {
                    id: 2,
                    name: "Стенограма",
                    onDate: "2020-09-25 12:42:38.17322",
                    documentTypeId: 2,
                    docId: 13
                },
                {
                    id: 3,
                    name: "Бележки",
                    onDate: "2020-09-25 12:42:38.17322",
                    documentTypeId: 3,
                    docId: 15
                },

            ]
        }

    },
    searchProtoResult: [

        {
            "protocolId": 1,
            "date": "1996-04-08",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 1,
                    "file": "-sof028-317-267-31.pdf",
                    "date": "1996-04-08",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 2,
            "date": "1996-04-08",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 2,
                    "file": "-sof028-317-267-33.pdf",
                    "date": "1996-04-08",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 3,
            "date": "1995-07-14",
            "title": "",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 3,
                    "file": "-sof028-317-267-39.pdf",
                    "date": "1995-07-14",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 4,
            "date": "1995-02-14",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 4,
                    "file": "-sof028-317-267-45.pdf",
                    "date": "1995-02-14",
                    "title": "",
                    "objectNumber": "317-267",
                    "cadastreIdentifier": "68134.300.436",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 5,
            "date": "1995-02-14",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 5,
                    "file": "-sof028-317-267-46.pdf",
                    "date": "1995-02-14",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 6,
            "date": "1992-10-23",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 6,
                    "file": "-sof028-317-267-48.pdf",
                    "date": "1992-10-23",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 7,
            "date": "1992-09-18",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 7,
                    "file": "-sof028-317-267-51.pdf",
                    "date": "1992-09-18",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 8,
            "date": "1991-01-29",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 8,
                    "file": "-sof028-317-267-52.pdf",
                    "date": "1991-01-29",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        },
        {
            "protocolId": 9,
            "date": "1989-03-24",
            "title": "Протокол",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",

            "documents": [
                {
                    "documentId": 9,
                    "file": "-sof028-317-267-56.pdf",
                    "date": "1989-03-24",
                    "title": "",
                    "objectNumber": "317-267",

                    "docTypeId": 5
                }
            ]
        }
    ],
    searchProjectResult: [
        {
            "projectId": 1,
            "title": "Централни хали - носеща стоманена покривна конструкция и колони",
            "year": 1998,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "selected": true,
            "documents": [
                {
                    "documentId": 1,
                    "file": "sof028-317-754-84-1-g-1.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 2,
                    "file": "sof028-317-754-84-1-g-2.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 3,
                    "file": "sof028-317-754-84-1-g-3.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 4,
                    "file": "sof028-317-754-84-1-g-4.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 5,
                    "file": "sof028-317-754-84-1-g-5.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 6,
                    "file": "sof028-317-754-84-1-g-6.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 7,
                    "file": "sof028-317-754-84-1-g-7.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 8,
                    "file": "sof028-317-754-84-1-g-8.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 9,
                    "file": "sof028-317-754-84-1-g-9.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 10,
                    "file": "sof028-317-754-84-1-g-10.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 11,
                    "file": "sof028-317-754-84-1-g-11.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 12,
                    "file": "sof028-317-754-84-1-g-12.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 13,
                    "file": "sof028-317-754-84-1-g-13.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 14,
                    "file": "sof028-317-754-84-1-g-14.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 15,
                    "file": "sof028-317-754-84-1-g-15.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 16,
                    "file": "sof028-317-754-84-1-g-16.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "g",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 17,
                    "file": "sof028-317-754-84-1-s-1.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "s",
                    "phase": "огнезащита"
                },
                {
                    "documentId": 18,
                    "file": "sof028-317-754-84-1-t-1.pdf",
                    "title": "Централни хали - носеща стоманена покривна конструкция и колони",
                    "docTypeId": "t",
                    "phase": "огнезащита"
                }
            ]
        },
        {
            "projectId": 2,
            "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция",
            "year": 1994,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 19,
                    "file": "sof028-317-755-84-1-g-1.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 20,
                    "file": "sof028-317-755-84-1-g-2.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 21,
                    "file": "sof028-317-755-84-1-g-3.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 22,
                    "file": "sof028-317-755-84-1-g-4.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 23,
                    "file": "sof028-317-755-84-1-g-5.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 24,
                    "file": "sof028-317-755-84-1-g-6.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 25,
                    "file": "sof028-317-755-84-1-g-7.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 26,
                    "file": "sof028-317-755-84-1-g-8.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 27,
                    "file": "sof028-317-755-84-1-g-9.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 28,
                    "file": "sof028-317-755-84-1-g-10.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 29,
                    "file": "sof028-317-755-84-1-g-11.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 30,
                    "file": "sof028-317-755-84-1-g-12.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 31,
                    "file": "sof028-317-755-84-1-g-13.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 32,
                    "file": "sof028-317-755-84-1-g-14.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 33,
                    "file": "sof028-317-755-84-1-g-15.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 34,
                    "file": "sof028-317-755-84-1-g-16.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 35,
                    "file": "sof028-317-755-84-1-g-17.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 36,
                    "file": "sof028-317-755-84-1-g-18.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 37,
                    "file": "sof028-317-755-84-1-g-19.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 38,
                    "file": "sof028-317-755-84-1-g-20.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 39,
                    "file": "sof028-317-755-84-1-g-21.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 40,
                    "file": "sof028-317-755-84-1-g-22.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 41,
                    "file": "sof028-317-755-84-1-g-23.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 42,
                    "file": "sof028-317-755-84-1-g-24.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 43,
                    "file": "sof028-317-755-84-1-g-25.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 44,
                    "file": "sof028-317-755-84-1-g-26.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 45,
                    "file": "sof028-317-755-84-1-g-27.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 46,
                    "file": "sof028-317-755-84-1-g-28.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 47,
                    "file": "sof028-317-755-84-1-g-29.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "g",
                    "phase": "констр., работна, км и кмд"
                },
                {
                    "documentId": 48,
                    "file": "sof028-317-755-84-1-t-1.pdf",
                    "title": "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ",
                    "docTypeId": "t",
                    "phase": "констр., работна, км и кмд"
                }
            ]
        },
        {
            "projectId": 3,
            "title": "Реконструкция и реставрация на Централни хали",
            "year": 1996,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 49,
                    "file": "sof028-317-756-84-1-g-1.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 50,
                    "file": "sof028-317-756-84-1-g-2.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 51,
                    "file": "sof028-317-756-84-1-g-3.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 52,
                    "file": "sof028-317-756-84-1-g-4.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 53,
                    "file": "sof028-317-756-84-1-g-5.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 54,
                    "file": "sof028-317-756-84-1-g-6.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 55,
                    "file": "sof028-317-756-84-1-g-7.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 56,
                    "file": "sof028-317-756-84-1-g-8.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 57,
                    "file": "sof028-317-756-84-1-g-9.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 58,
                    "file": "sof028-317-756-84-1-g-10.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 59,
                    "file": "sof028-317-756-84-1-g-11.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 60,
                    "file": "sof028-317-756-84-1-g-12.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 61,
                    "file": "sof028-317-756-84-1-g-13.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 62,
                    "file": "sof028-317-756-84-1-g-14.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "g",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 63,
                    "file": "sof028-317-756-84-1-s-1.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "s",
                    "phase": "идейна, архитектурна"
                },
                {
                    "documentId": 64,
                    "file": "sof028-317-756-84-1-t-1.pdf",
                    "title": "Реконструкция и реставрация на Централни хали",
                    "docTypeId": "t",
                    "phase": "идейна, архитектурна"
                }
            ]
        },
        {
            "projectId": 4,
            "title": "Централни хали - вариантни решения за изпълнение на пожарогаситeлната инсталация, ОВ инсталацията и осветителната инсталация",
            "year": 1996,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 65,
                    "file": "sof028-317-757-84-1-g-1.pdf",
                    "title": "Централни хали - вариантни решения за изпълнение на пожарогаситeлната инсталация, ОВ инсталацията и осветителната инсталация",
                    "docTypeId": "g",
                    "phase": "крр"
                },
                {
                    "documentId": 66,
                    "file": "sof028-317-757-84-1-t-1.pdf",
                    "title": "Централни хали - вариантни решения за изпълнение на пожарогаситeлната инсталация, ОВ инсталацията и осветителната инсталация",
                    "docTypeId": "t",
                    "phase": "крр"
                }
            ]
        },
        {
            "projectId": 5,
            "title": "Централни хали - проект за вътрешно преустройство - Ащром",
            "year": 1998,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 67,
                    "file": "sof028-317-758-84-1-g-1.pdf",
                    "title": "Централни хали - проект за вътрешно преустройство - Ащром",
                    "docTypeId": "g",
                    "phase": "арх."
                },
                {
                    "documentId": 68,
                    "file": "sof028-317-758-84-1-g-2.pdf",
                    "title": "Централни хали - проект за вътрешно преустройство - Ащром",
                    "docTypeId": "g",
                    "phase": "арх."
                },
                {
                    "documentId": 69,
                    "file": "sof028-317-758-84-1-g-3.pdf",
                    "title": "Централни хали - проект за вътрешно преустройство - Ащром",
                    "docTypeId": "g",
                    "phase": "арх."
                },
                {
                    "documentId": 70,
                    "file": "sof028-317-758-84-1-s-1.pdf",
                    "title": "Централни хали - проект за вътрешно преустройство - Ащром",
                    "docTypeId": "s",
                    "phase": "арх."
                },
                {
                    "documentId": 71,
                    "file": "sof028-317-758-84-1-t-1.pdf",
                    "title": "Централни хали - проект за вътрешно преустройство - Ащром",
                    "docTypeId": "t",
                    "phase": "арх."
                }
            ]
        },
        {
            "projectId": 6,
            "title": "Реставрация на Централни хали",
            "year": 1990,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 72,
                    "file": "sof028-317-6710-841-1-s-1.pdf",
                    "title": "Реставрация на Централни хали ",
                    "docTypeId": "s",
                    "phase": "ТП"
                },
                {
                    "documentId": 73,
                    "file": "sof028-317-6710-841-1-t-1.pdf",
                    "title": "Реставрация на Централни хали ",
                    "docTypeId": "t",
                    "phase": "ТП"
                }
            ]
        },
        {
            "projectId": 7,
            "title": "Основен ремонт на покрива и фасадите на Централни хали",
            "year": 1980,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 74,
                    "file": "sof028-317-7540-934-1-t-1.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали",
                    "docTypeId": "t",
                    "phase": "ген.сметка, работна"
                },
                {
                    "documentId": 75,
                    "file": "sof028-317-7541-934-1-t-1.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали",
                    "docTypeId": "t",
                    "phase": "ген.сметка, работна"
                }
            ]
        },
        {
            "projectId": 70,
            "title": "Основен ремонт на покрива и фасадите на Централни хали - реставрация",
            "year": 1981,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 76,
                    "file": "sof028-317-7542-934-1-g-1.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали - реставрация",
                    "docTypeId": "g",
                    "phase": "АС-ПСД, работна"
                },
                {
                    "documentId": 77,
                    "file": "sof028-317-7542-934-1-g-2.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали - реставрация",
                    "docTypeId": "g",
                    "phase": "АС-ПСД, работна"
                },
                {
                    "documentId": 78,
                    "file": "sof028-317-7542-934-1-g-3.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали - реставрация",
                    "docTypeId": "g",
                    "phase": "АС-ПСД, работна"
                },
                {
                    "documentId": 79,
                    "file": "sof028-317-7542-934-1-g-4.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали - реставрация",
                    "docTypeId": "g",
                    "phase": "АС-ПСД, работна"
                },
                {
                    "documentId": 80,
                    "file": "sof028-317-7542-934-1-g-5.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали - реставрация",
                    "docTypeId": "g",
                    "phase": "АС-ПСД, работна"
                },
                {
                    "documentId": 81,
                    "file": "sof028-317-7542-934-1-t-1.pdf",
                    "title": "Основен ремонт на покрива и фасадите на Централни хали - реставрация",
                    "docTypeId": "t",
                    "phase": "АС-ПСД, работна"
                }
            ]
        },
        {
            "projectId": 8,
            "title": "Централни хали",
            "year": 1984,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 82,
                    "file": "sof028-317-7714-951-1-g-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 83,
                    "file": "sof028-317-7714-951-1-g-2.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 84,
                    "file": "sof028-317-7714-951-1-g-3.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 85,
                    "file": "sof028-317-7714-951-1-g-4.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 86,
                    "file": "sof028-317-7714-951-1-g-5.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 87,
                    "file": "sof028-317-7714-951-1-g-6.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 88,
                    "file": "sof028-317-7714-951-1-g-7.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 89,
                    "file": "sof028-317-7714-951-1-g-8.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "арх. заснемане, съществуващо положение"
                },
                {
                    "documentId": 90,
                    "file": "sof028-317-7714-951-1-t-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "t",
                    "phase": "арх. заснемане, съществуващо положение"
                }
            ]
        },
        {
            "projectId": 10,
            "title": "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
            "year": 2020,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 92,
                    "file": "sof028-317-8093-1001-1-a-1.pdf",
                    "title": "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ",
                    "docTypeId": "a",
                    "phase": "становище"
                },
                {
                    "documentId": 93,
                    "file": "sof028-317-8093-1001-1-g-1.pdf",
                    "title": "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ",
                    "docTypeId": "g",
                    "phase": "становище"
                },
                {
                    "documentId": 94,
                    "file": "sof028-317-8093-1001-1-p-1.pdf",
                    "title": "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ",
                    "docTypeId": "p",
                    "phase": "становище"
                },
                {
                    "documentId": 95,
                    "file": "sof028-317-8093-1001-1-s-1.pdf",
                    "title": "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ",
                    "docTypeId": "s",
                    "phase": "становище"
                },
                {
                    "documentId": 96,
                    "file": "sof028-317-8093-1001-1-t-1.pdf",
                    "title": "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ",
                    "docTypeId": "t",
                    "phase": "становище"
                }
            ]
        },
        {
            "projectId": 11,
            "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
            "year": 2020,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 91,
                    "file": "sof028-317-8092-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 97,
                    "file": "sof028-317-8094-1001-1-g-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 98,
                    "file": "sof028-317-8094-1001-1-g-2.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 99,
                    "file": "sof028-317-8094-1001-1-g-3.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 100,
                    "file": "sof028-317-8094-1001-1-g-4.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 101,
                    "file": "sof028-317-8094-1001-1-g-5.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 102,
                    "file": "sof028-317-8094-1001-1-g-6.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 103,
                    "file": "sof028-317-8094-1001-1-g-7.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 104,
                    "file": "sof028-317-8094-1001-1-g-8.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 105,
                    "file": "sof028-317-8094-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 106,
                    "file": "sof028-317-8095-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали - постоянна организация и безопасност на движението",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 107,
                    "file": "sof028-317-8096-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 108,
                    "file": "sof028-317-8097-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 109,
                    "file": "sof028-317-8098-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 110,
                    "file": "sof028-317-8099-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 111,
                    "file": "sof028-317-8100-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 112,
                    "file": "sof028-317-8101-1001-1-g-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "g",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                },
                {
                    "documentId": 113,
                    "file": "sof028-317-8101-1001-1-t-1.pdf",
                    "title": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали",
                    "docTypeId": "t",
                    "phase": "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали"
                }
            ]
        },
        {
            "projectId": 12,
            "title": "Централни хали",
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "selected": true,
            "documents": [
                {
                    "documentId": 114,
                    "file": "sof028-317-8102-1001-1-t-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                }
            ]
        },
        {
            "projectId": 13,
            "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
            "year": 2020,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 115,
                    "file": "sof028-317-8103-1001-1-g-1.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 116,
                    "file": "sof028-317-8103-1001-1-g-2.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 117,
                    "file": "sof028-317-8103-1001-1-g-3.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 118,
                    "file": "sof028-317-8103-1001-1-g-4.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 119,
                    "file": "sof028-317-8103-1001-1-g-5.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 120,
                    "file": "sof028-317-8103-1001-1-g-6.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 121,
                    "file": "sof028-317-8103-1001-1-g-7.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 122,
                    "file": "sof028-317-8103-1001-1-g-8.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 123,
                    "file": "sof028-317-8103-1001-1-g-9.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 124,
                    "file": "sof028-317-8103-1001-1-g-10.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 125,
                    "file": "sof028-317-8103-1001-1-g-11.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 126,
                    "file": "sof028-317-8103-1001-1-g-12.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 127,
                    "file": "sof028-317-8103-1001-1-g-13.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 128,
                    "file": "sof028-317-8103-1001-1-g-14.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 129,
                    "file": "sof028-317-8103-1001-1-g-15.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 130,
                    "file": "sof028-317-8103-1001-1-g-16.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 131,
                    "file": "sof028-317-8103-1001-1-g-17.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 132,
                    "file": "sof028-317-8103-1001-1-g-18.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 133,
                    "file": "sof028-317-8103-1001-1-g-19.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 134,
                    "file": "sof028-317-8103-1001-1-g-20.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 135,
                    "file": "sof028-317-8103-1001-1-g-21.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 136,
                    "file": "sof028-317-8103-1001-1-g-22.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 137,
                    "file": "sof028-317-8103-1001-1-g-23.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 138,
                    "file": "sof028-317-8103-1001-1-g-24.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 139,
                    "file": "sof028-317-8103-1001-1-t-1.pdf",
                    "title": "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора",
                    "docTypeId": "t",
                    "phase": "фасадна реставрация, арх."
                }
            ]
        },
        {
            "projectId": 14,
            "title": "Централни хали - състояние на фасадите",
            "year": 1991,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 140,
                    "file": "sof028-317-8182-1017-1-t-1.pdf",
                    "title": "Централни хали - състояние на фасадите",
                    "docTypeId": "t",
                    "phase": "доклад"
                }
            ]
        },
        {
            "projectId": 15,
            "title": "Централни хали",
            "year": 1889,
            "objectNumber": "317-267",
            "cadastreIdentifier": "68134.300.436",
            "documents": [
                {
                    "documentId": 141,
                    "file": "sof028-317-8263-1029-1-t-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "t",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 142,
                    "file": "sof028-317-8424-1049-1-g-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 143,
                    "file": "sof028-317-8424-1049-1-g-2.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 144,
                    "file": "sof028-317-8424-1049-1-g-3.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 145,
                    "file": "sof028-317-8424-1049-1-g-4.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 146,
                    "file": "sof028-317-8424-1049-1-g-5.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 147,
                    "file": "sof028-317-8424-1049-1-g-6.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 148,
                    "file": "sof028-317-8424-1049-1-t-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "t",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 149,
                    "file": "sof028-317-8425-1049-1-t-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "t",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 150,
                    "file": "sof028-317-8488-1057-1-g-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 151,
                    "file": "sof028-317-8488-1057-1-g-2.pdf",
                    "title": "Централни хали",
                    "docTypeId": "g",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 152,
                    "file": "sof028-317-8488-1057-1-p-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "p",
                    "phase": "фасадна реставрация, арх."
                },
                {
                    "documentId": 153,
                    "file": "sof028-317-8488-1057-1-s-1.pdf",
                    "title": "Централни хали",
                    "docTypeId": "s",
                    "phase": "фасадна реставрация, арх."
                }
            ]
        }
    ],
    categories: [
        { categoryId: 1, title: "археологически" },
        { categoryId: 2, title: "исторически" },
        { categoryId: 3, title: "архитектурно-строителни" },
        { categoryId: 4, title: "художествени" },
        { categoryId: 5, title: "урбанистични" },
        { categoryId: 6, title: "културен ландшафт" },
        { categoryId: 7, title: "парково и градинско изкуство" },
        { categoryId: 8, title: "етнографски" },
        { categoryId: 9, title: "културен маршрут" },
        { categoryId: 10, title: "Архитектурно-строителна и художествена" },

    ],


    dossier: {
        name: "Централни хали",
        objectNumber: "317-267",
        cadastreIdentifier: "68134.300.436",
        coordinates: [{
            lat: 42.7001838679107,
            lng: 23.322044613419724
        }],
        classificationId: 2,
        classificationDocs: [
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 },
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 }

        ],
        categoryId: 10,
        categoryDocs: [
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 },
            { documentId: 5000, file: "reg-sof-001.pdf", date: "1998-04-03", title: "Държавен вестник", docTypeId: 12 }

        ],
        NKCType3d: "единичина",
        regime: "",
        ekatte: "68134",
        address: "бул.”Княгиня Мария Луиза”  № 23",
        cadastreData: "68134.300.436",
        oldCadastreData: "68134.300.436",
        history: [
            { historyId: 1, onDate: "2021-01-25 12:42:38.17322", username: "Александър Константинов" },

        ],
        protocols: [

            {
                "protocolId": 1,
                "date": "1996-04-08",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 1,
                        "file": "-sof028-317-267-31.pdf",
                        "date": "1996-04-08",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 2,
                "date": "1996-04-08",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 2,
                        "file": "-sof028-317-267-33.pdf",
                        "date": "1996-04-08",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 3,
                "date": "1995-07-14",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 3,
                        "file": "-sof028-317-267-39.pdf",
                        "date": "1995-07-14",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 4,
                "date": "1995-02-14",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 4,
                        "file": "-sof028-317-267-45.pdf",
                        "date": "1995-02-14",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 5,
                "date": "1995-02-14",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 5,
                        "file": "-sof028-317-267-46.pdf",
                        "date": "1995-02-14",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 6,
                "date": "1992-10-23",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 6,
                        "file": "-sof028-317-267-48.pdf",
                        "date": "1992-10-23",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 7,
                "date": "1992-09-18",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 7,
                        "file": "-sof028-317-267-51.pdf",
                        "date": "1992-09-18",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 8,
                "date": "1991-01-29",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 8,
                        "file": "-sof028-317-267-52.pdf",
                        "date": "1991-01-29",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            },
            {
                "protocolId": 9,
                "date": "1989-03-24",
                "title": "Протокол",
                "documents": [
                    {
                        "documentId": 9,
                        "file": "-sof028-317-267-56.pdf",
                        "date": "1989-03-24",
                        "title": "Протокол",
                        "docTypeId": 5
                    }
                ]
            }

        ],
        researchDocs: [
            { documentId: 1001, file: '-sof028-317-267-1.pdf', docTypeId: 12 },
            { documentId: 1002, file: '-sof028-317-267-2.pdf', docTypeId: 1 },
            { documentId: 1003, file: '-sof028-317-267-3.pdf', docTypeId: 3, date: '2011-07-18' },
            { documentId: 1004, file: '-sof028-317-267-4.pdf', docTypeId: 3, date: '2011-06-10' },
            { documentId: 1005, file: '-sof028-317-267-5.pdf', docTypeId: 12 },
            { documentId: 1006, file: '-sof028-317-267-6.pdf', docTypeId: 3, date: '2005-08-22' },
            { documentId: 1007, file: '-sof028-317-267-7.pdf', docTypeId: 3, date: '2011-05-13' },
            { documentId: 1008, file: '-sof028-317-267-8.pdf', docTypeId: 3, date: '2005-08-22' },
            { documentId: 1009, file: '-sof028-317-267-9.pdf', docTypeId: 3, date: '2000-08-22' },
            { documentId: 1010, file: '-sof028-317-267-10.pdf', docTypeId: 7, date: '2000-05-25' },
            { documentId: 1011, file: '-sof028-317-267-11.pdf', docTypeId: 3, date: '2000-02-01' },
            { documentId: 1012, file: '-sof028-317-267-12.pdf', docTypeId: 3, date: '1999-06-16' },
            { documentId: 1013, file: '-sof028-317-267-13.pdf', docTypeId: 6, date: '1999-06-04' },
            { documentId: 1014, file: '-sof028-317-267-14.pdf', docTypeId: 3, date: '1999-06-02' },
            { documentId: 1015, file: '-sof028-317-267-15.pdf', docTypeId: 3, date: '1999-04-27' },
            { documentId: 1016, file: '-sof028-317-267-16.pdf', docTypeId: 7, date: '1999-05-25' },
            { documentId: 1017, file: '-sof028-317-267-17.pdf', docTypeId: 7, date: '1999-05-14' },
            { documentId: 1018, file: '-sof028-317-267-18.pdf', docTypeId: 3, date: '1999-02-12' },
            { documentId: 1019, file: '-sof028-317-267-19.pdf', docTypeId: 3, date: '1999-02-12' },
            { documentId: 1020, file: '-sof028-317-267-20.pdf', docTypeId: 3, date: '1999-01-19' },
            { documentId: 1021, file: '-sof028-317-267-21.pdf', docTypeId: 3, date: '1998-10-15' },
            { documentId: 1022, file: '-sof028-317-267-22.pdf', docTypeId: 3, date: '1998-10-15' },
            { documentId: 1023, file: '-sof028-317-267-23.pdf', docTypeId: 3, date: '1998-11-04' },
            { documentId: 1024, file: '-sof028-317-267-24.pdf', docTypeId: 3, date: '1998-09-01' },
            { documentId: 1025, file: '-sof028-317-267-25.pdf', docTypeId: 3, date: '1998-09-11' },
            { documentId: 1026, file: '-sof028-317-267-26.pdf', docTypeId: 3 },
            { documentId: 1027, file: '-sof028-317-267-27.pdf', docTypeId: 7, date: '1999-02-02' },
            { documentId: 1028, file: '-sof028-317-267-28.pdf', docTypeId: 7 },
            { documentId: 1029, file: '-sof028-317-267-29.pdf', docTypeId: 7, date: '1999-02-03' },
            { documentId: 1030, file: '-sof028-317-267-30.pdf', docTypeId: 3, date: '1997-11-27' },
            { documentId: 1031, file: '-sof028-317-267-32.pdf', docTypeId: 12, date: '1996-04-05' },
            { documentId: 1032, file: '-sof028-317-267-34.pdf', docTypeId: 3, date: '1996-04-17' },
            { documentId: 1033, file: '-sof028-317-267-35.pdf', docTypeId: 3, date: '1996-03-28' },
            { documentId: 1034, file: '-sof028-317-267-36.pdf', docTypeId: 3, date: '1996-03-14' },
            { documentId: 1035, file: '-sof028-317-267-37.pdf', docTypeId: 3, date: '1996-02-21' },
            { documentId: 1036, file: '-sof028-317-267-38.pdf', docTypeId: 3, date: '1995-07-27' },
            { documentId: 1037, file: '-sof028-317-267-40.pdf', docTypeId: 12 },
            { documentId: 1038, file: '-sof028-317-267-41.pdf', docTypeId: 12 },
            { documentId: 1039, file: '-sof028-317-267-42.pdf', docTypeId: 3, date: '1995-07-14' },
            { documentId: 1040, file: '-sof028-317-267-43.pdf', docTypeId: 3, date: '1995-05-18' },
            { documentId: 1041, file: '-sof028-317-267-44.pdf', docTypeId: 3, date: '1995-05-18' },
            { documentId: 1042, file: '-sof028-317-267-47.pdf', docTypeId: 3, date: '1995-05-18' },
            { documentId: 1043, file: '-sof028-317-267-49.pdf', docTypeId: 3, date: '1992-10-12' },
            { documentId: 1044, file: '-sof028-317-267-50.pdf', docTypeId: 12, date: '1992-11-16' },
            { documentId: 1045, file: '-sof028-317-267-53.pdf', docTypeId: 12 },
            { documentId: 1046, file: '-sof028-317-267-54.pdf', docTypeId: 3, date: '1991-01-29' },
            { documentId: 1047, file: '-sof028-317-267-55.pdf', docTypeId: 7, date: '1990-10-30' },
            { documentId: 1048, file: '-sof028-317-267-57.pdf', docTypeId: 3, date: '1970-11-30' },
            { documentId: 1049, file: '-sof028-317-267-58.pdf', docTypeId: 11 },
            { documentId: 1050, file: '-sof028-317-267-59.pdf', docTypeId: 12, date: '1999-01-12' },
            { documentId: 1051, file: '-sof028-317-267-60.pdf', docTypeId: 12 },
            { documentId: 1052, file: '-sof028-317-267-61.pdf', docTypeId: 12 },
            { documentId: 1053, file: '-sof028-317-267-62.pdf', docTypeId: 12 },
            { documentId: 1054, file: '-sof028-317-267-63.pdf', docTypeId: 12 },
            { documentId: 1055, file: '-sof028-317-267-64.pdf', docTypeId: 4 },
        ]
    },

    projects: [
        {
            projectId: 2,
            name: "Проект 123",
            onDate: "2020-09-25 12:42:38.17322",
        },
        {
            projectId: 3,
            name: "Проект 456",
            onDate: "2020-09-25 12:42:38.17322",
        }

    ],
    researchDocTypes: [
        { docTypeId: 1, name: "Протокол" },
        { docTypeId: 2, name: "Експертиза" },
        { docTypeId: 3, name: "Становище" }
    ],
    techDocTypes: [
        { docTypeId: 1, name: "Снимка" },
        { docTypeId: 2, name: "Плака" },
        { docTypeId: 3, name: "Фактура" },
    ],
    classifications: [
        { classificationId: 1, title: "световно значение" },
        { classificationId: 2, title: "национално значение" },
        { classificationId: 3, title: "местно значение" },
        { classificationId: 4, title: "ансамблово значение" },
        { classificationId: 5, title: "за сведение" }

    ],

    log: {
        count: 100,
        rows: [
            {
                id: 1,
                logId: 1,
                onDate: "2020-09-25 12:42:38.17322",
                userId: "Александър Константинов",
                action: "Промяна на досие",
                data: '{ "Id": 1, "IsVisible": true, "DocumentId": "da6f2821-4ef3-4cf7-80ab-983acd4324f4" }'
            },
            {
                id: 2,
                logId: 2,
                onDate: "2020-09-25 12:42:38.17322",
                userId: "Александър Константинов",
                action: "Промяна на досие",
                data: '{ "Id": 1, "IsVisible": true, "DocumentId": "da6f2821-4ef3-4cf7-80ab-983acd4324f4" }'
            },
            {
                id: 3,
                logId: 3,
                onDate: "2020-09-25 12:42:38.17322",
                userId: "Александър Константинов",
                action: "Промяна на досие",
                data: '{ "Id": 1, "IsVisible": true, "DocumentId": "da6f2821-4ef3-4cf7-80ab-983acd4324f4" }'
            }
        ]
    },
    users: [
        { username: "admin", fullname: "Александър Константинов" },
        { username: "admin2", fullname: "Стоян Константинов" }
    ],
    documentSearchResult:
        [
            { id: 1, documentId: 1, file: "-sof028-317-267-31.pdf", date: "1996-04-08", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 2, documentId: 2, file: "-sof028-317-267-33.pdf", date: "1996-04-08", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3, documentId: 3, file: "-sof028-317-267-39.pdf", date: "1995-07-14", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 4, documentId: 4, file: "-sof028-317-267-45.pdf", date: "1995-02-14", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 5, documentId: 5, file: "-sof028-317-267-46.pdf", date: "1995-02-14", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 6, documentId: 6, file: "-sof028-317-267-48.pdf", date: "1992-10-23", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 7, documentId: 7, file: "-sof028-317-267-51.pdf", date: "1992-09-18", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 8, documentId: 8, file: "-sof028-317-267-52.pdf", date: "1991-01-29", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 9, documentId: 9, file: "-sof028-317-267-56.pdf", date: "1989-03-24", title: "", docTypeId: 5, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 301, documentId: 301, file: 'sof028-317-754-84-1-g-1.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 302, documentId: 302, file: 'sof028-317-754-84-1-g-2.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 303, documentId: 303, file: 'sof028-317-754-84-1-g-3.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 304, documentId: 304, file: 'sof028-317-754-84-1-g-4.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 305, documentId: 305, file: 'sof028-317-754-84-1-g-5.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 306, documentId: 306, file: 'sof028-317-754-84-1-g-6.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 307, documentId: 307, file: 'sof028-317-754-84-1-g-7.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 308, documentId: 308, file: 'sof028-317-754-84-1-g-8.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 309, documentId: 309, file: 'sof028-317-754-84-1-g-9.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3010, documentId: 3010, file: 'sof028-317-754-84-1-g-10.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3011, documentId: 3011, file: 'sof028-317-754-84-1-g-11.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3012, documentId: 3012, file: 'sof028-317-754-84-1-g-12.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3013, documentId: 3013, file: 'sof028-317-754-84-1-g-13.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3014, documentId: 3014, file: 'sof028-317-754-84-1-g-14.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3015, documentId: 3015, file: 'sof028-317-754-84-1-g-15.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3016, documentId: 3016, file: 'sof028-317-754-84-1-g-16.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3017, documentId: 3017, file: 'sof028-317-754-84-1-s-1.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 's', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3018, documentId: 3018, file: 'sof028-317-754-84-1-t-1.pdf', title: "Централни хали - носеща стоманена покривна конструкция и колони", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3019, documentId: 3019, file: 'sof028-317-755-84-1-g-1.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3020, documentId: 3020, file: 'sof028-317-755-84-1-g-2.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3021, documentId: 3021, file: 'sof028-317-755-84-1-g-3.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3022, documentId: 3022, file: 'sof028-317-755-84-1-g-4.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3023, documentId: 3023, file: 'sof028-317-755-84-1-g-5.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3024, documentId: 3024, file: 'sof028-317-755-84-1-g-6.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3025, documentId: 3025, file: 'sof028-317-755-84-1-g-7.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3026, documentId: 3026, file: 'sof028-317-755-84-1-g-8.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3027, documentId: 3027, file: 'sof028-317-755-84-1-g-9.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3028, documentId: 3028, file: 'sof028-317-755-84-1-g-10.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3029, documentId: 3029, file: 'sof028-317-755-84-1-g-11.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3030, documentId: 3030, file: 'sof028-317-755-84-1-g-12.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3031, documentId: 3031, file: 'sof028-317-755-84-1-g-13.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3032, documentId: 3032, file: 'sof028-317-755-84-1-g-14.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3033, documentId: 3033, file: 'sof028-317-755-84-1-g-15.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3034, documentId: 3034, file: 'sof028-317-755-84-1-g-16.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3035, documentId: 3035, file: 'sof028-317-755-84-1-g-17.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3036, documentId: 3036, file: 'sof028-317-755-84-1-g-18.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3037, documentId: 3037, file: 'sof028-317-755-84-1-g-19.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3038, documentId: 3038, file: 'sof028-317-755-84-1-g-20.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3039, documentId: 3039, file: 'sof028-317-755-84-1-g-21.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3040, documentId: 3040, file: 'sof028-317-755-84-1-g-22.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3041, documentId: 3041, file: 'sof028-317-755-84-1-g-23.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3042, documentId: 3042, file: 'sof028-317-755-84-1-g-24.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3043, documentId: 3043, file: 'sof028-317-755-84-1-g-25.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3044, documentId: 3044, file: 'sof028-317-755-84-1-g-26.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3045, documentId: 3045, file: 'sof028-317-755-84-1-g-27.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3046, documentId: 3046, file: 'sof028-317-755-84-1-g-28.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3047, documentId: 3047, file: 'sof028-317-755-84-1-g-29.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3048, documentId: 3048, file: 'sof028-317-755-84-1-t-1.pdf', title: "Реконструкция и реставрация на Централни хали - проект за преработка на покривната конструкция ", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3049, documentId: 3049, file: 'sof028-317-756-84-1-g-1.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3050, documentId: 3050, file: 'sof028-317-756-84-1-g-2.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3051, documentId: 3051, file: 'sof028-317-756-84-1-g-3.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3052, documentId: 3052, file: 'sof028-317-756-84-1-g-4.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3053, documentId: 3053, file: 'sof028-317-756-84-1-g-5.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3054, documentId: 3054, file: 'sof028-317-756-84-1-g-6.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3055, documentId: 3055, file: 'sof028-317-756-84-1-g-7.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3056, documentId: 3056, file: 'sof028-317-756-84-1-g-8.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3057, documentId: 3057, file: 'sof028-317-756-84-1-g-9.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3058, documentId: 3058, file: 'sof028-317-756-84-1-g-10.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3059, documentId: 3059, file: 'sof028-317-756-84-1-g-11.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3060, documentId: 3060, file: 'sof028-317-756-84-1-g-12.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3061, documentId: 3061, file: 'sof028-317-756-84-1-g-13.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3062, documentId: 3062, file: 'sof028-317-756-84-1-g-14.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3063, documentId: 3063, file: 'sof028-317-756-84-1-s-1.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 's', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3064, documentId: 3064, file: 'sof028-317-756-84-1-t-1.pdf', title: "Реконструкция и реставрация на Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3065, documentId: 3065, file: 'sof028-317-757-84-1-g-1.pdf', title: "Централни хали - вариантни решения за изпълнение на пожарогаситeлната инсталация, ОВ инсталацията и осветителната инсталация", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3066, documentId: 3066, file: 'sof028-317-757-84-1-t-1.pdf', title: "Централни хали - вариантни решения за изпълнение на пожарогаситeлната инсталация, ОВ инсталацията и осветителната инсталация", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3067, documentId: 3067, file: 'sof028-317-758-84-1-g-1.pdf', title: "Централни хали - проект за вътрешно преустройство - Ащром", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3068, documentId: 3068, file: 'sof028-317-758-84-1-g-2.pdf', title: "Централни хали - проект за вътрешно преустройство - Ащром", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3069, documentId: 3069, file: 'sof028-317-758-84-1-g-3.pdf', title: "Централни хали - проект за вътрешно преустройство - Ащром", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3070, documentId: 3070, file: 'sof028-317-758-84-1-s-1.pdf', title: "Централни хали - проект за вътрешно преустройство - Ащром", docTypeId: 's', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3071, documentId: 3071, file: 'sof028-317-758-84-1-t-1.pdf', title: "Централни хали - проект за вътрешно преустройство - Ащром", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3072, documentId: 3072, file: 'sof028-317-6710-841-1-s-1.pdf', title: "Реставрация на Централни хали ", docTypeId: 's', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3073, documentId: 3073, file: 'sof028-317-6710-841-1-t-1.pdf', title: "Реставрация на Централни хали ", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3074, documentId: 3074, file: 'sof028-317-7540-934-1-t-1.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3075, documentId: 3075, file: 'sof028-317-7541-934-1-t-1.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3076, documentId: 3076, file: 'sof028-317-7542-934-1-g-1.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали - реставрация", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3077, documentId: 3077, file: 'sof028-317-7542-934-1-g-2.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали - реставрация", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3078, documentId: 3078, file: 'sof028-317-7542-934-1-g-3.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали - реставрация", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3079, documentId: 3079, file: 'sof028-317-7542-934-1-g-4.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали - реставрация", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3080, documentId: 3080, file: 'sof028-317-7542-934-1-g-5.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали - реставрация", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3081, documentId: 3081, file: 'sof028-317-7542-934-1-t-1.pdf', title: "Основен ремонт на покрива и фасадите на Централни хали - реставрация", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3082, documentId: 3082, file: 'sof028-317-7714-951-1-g-1.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3083, documentId: 3083, file: 'sof028-317-7714-951-1-g-2.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3084, documentId: 3084, file: 'sof028-317-7714-951-1-g-3.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3085, documentId: 3085, file: 'sof028-317-7714-951-1-g-4.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3086, documentId: 3086, file: 'sof028-317-7714-951-1-g-5.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3087, documentId: 3087, file: 'sof028-317-7714-951-1-g-6.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3088, documentId: 3088, file: 'sof028-317-7714-951-1-g-7.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3089, documentId: 3089, file: 'sof028-317-7714-951-1-g-8.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3090, documentId: 3090, file: 'sof028-317-7714-951-1-t-1.pdf', title: "Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3091, documentId: 3091, file: 'sof028-317-8092-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3092, documentId: 3092, file: 'sof028-317-8093-1001-1-a-1.pdf', title: "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ", docTypeId: 'a', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3093, documentId: 3093, file: 'sof028-317-8093-1001-1-g-1.pdf', title: "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3094, documentId: 3094, file: 'sof028-317-8093-1001-1-p-1.pdf', title: "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ", docTypeId: 'p', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3095, documentId: 3095, file: 'sof028-317-8093-1001-1-s-1.pdf', title: "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ", docTypeId: 's', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3096, documentId: 3096, file: 'sof028-317-8093-1001-1-t-1.pdf', title: "Виза и ИТП за вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали ", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3097, documentId: 3097, file: 'sof028-317-8094-1001-1-g-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3098, documentId: 3098, file: 'sof028-317-8094-1001-1-g-2.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 3099, documentId: 3099, file: 'sof028-317-8094-1001-1-g-3.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30100, documentId: 30100, file: 'sof028-317-8094-1001-1-g-4.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30101, documentId: 30101, file: 'sof028-317-8094-1001-1-g-5.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30102, documentId: 30102, file: 'sof028-317-8094-1001-1-g-6.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30103, documentId: 30103, file: 'sof028-317-8094-1001-1-g-7.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30104, documentId: 30104, file: 'sof028-317-8094-1001-1-g-8.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30105, documentId: 30105, file: 'sof028-317-8094-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30106, documentId: 30106, file: 'sof028-317-8095-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали - постоянна организация и безопасност на движението", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30107, documentId: 30107, file: 'sof028-317-8096-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30108, documentId: 30108, file: 'sof028-317-8097-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30109, documentId: 30109, file: 'sof028-317-8098-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30110, documentId: 30110, file: 'sof028-317-8099-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30111, documentId: 30111, file: 'sof028-317-8100-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30112, documentId: 30112, file: 'sof028-317-8101-1001-1-g-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30113, documentId: 30113, file: 'sof028-317-8101-1001-1-t-1.pdf', title: "Вътрешно преустройство на ниво 0.00 и ниво + 4.00, Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30114, documentId: 30114, file: 'sof028-317-8102-1001-1-t-1.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30115, documentId: 30115, file: 'sof028-317-8103-1001-1-g-1.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30116, documentId: 30116, file: 'sof028-317-8103-1001-1-g-2.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30117, documentId: 30117, file: 'sof028-317-8103-1001-1-g-3.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30118, documentId: 30118, file: 'sof028-317-8103-1001-1-g-4.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30119, documentId: 30119, file: 'sof028-317-8103-1001-1-g-5.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30120, documentId: 30120, file: 'sof028-317-8103-1001-1-g-6.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30121, documentId: 30121, file: 'sof028-317-8103-1001-1-g-7.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30122, documentId: 30122, file: 'sof028-317-8103-1001-1-g-8.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30123, documentId: 30123, file: 'sof028-317-8103-1001-1-g-9.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30124, documentId: 30124, file: 'sof028-317-8103-1001-1-g-10.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30125, documentId: 30125, file: 'sof028-317-8103-1001-1-g-11.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30126, documentId: 30126, file: 'sof028-317-8103-1001-1-g-12.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30127, documentId: 30127, file: 'sof028-317-8103-1001-1-g-13.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30128, documentId: 30128, file: 'sof028-317-8103-1001-1-g-14.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30129, documentId: 30129, file: 'sof028-317-8103-1001-1-g-15.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30130, documentId: 30130, file: 'sof028-317-8103-1001-1-g-16.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30131, documentId: 30131, file: 'sof028-317-8103-1001-1-g-17.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30132, documentId: 30132, file: 'sof028-317-8103-1001-1-g-18.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30133, documentId: 30133, file: 'sof028-317-8103-1001-1-g-19.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30134, documentId: 30134, file: 'sof028-317-8103-1001-1-g-20.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30135, documentId: 30135, file: 'sof028-317-8103-1001-1-g-21.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30136, documentId: 30136, file: 'sof028-317-8103-1001-1-g-22.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30137, documentId: 30137, file: 'sof028-317-8103-1001-1-g-23.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30138, documentId: 30138, file: 'sof028-317-8103-1001-1-g-24.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30139, documentId: 30139, file: 'sof028-317-8103-1001-1-t-1.pdf', title: "Централни хали - оценка на ценността на сградата и нейното околно пространство с анализ на състоянието на интериора", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30140, documentId: 30140, file: 'sof028-317-8182-1017-1-t-1.pdf', title: "Централни хали - състояние на фасадите", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30141, documentId: 30141, file: 'sof028-317-8263-1029-1-t-1.pdf', title: "Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30142, documentId: 30142, file: 'sof028-317-8424-1049-1-g-1.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30143, documentId: 30143, file: 'sof028-317-8424-1049-1-g-2.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30144, documentId: 30144, file: 'sof028-317-8424-1049-1-g-3.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30145, documentId: 30145, file: 'sof028-317-8424-1049-1-g-4.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30146, documentId: 30146, file: 'sof028-317-8424-1049-1-g-5.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30147, documentId: 30147, file: 'sof028-317-8424-1049-1-g-6.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30148, documentId: 30148, file: 'sof028-317-8424-1049-1-t-1.pdf', title: "Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30149, documentId: 30149, file: 'sof028-317-8425-1049-1-t-1.pdf', title: "Централни хали", docTypeId: 't', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30150, documentId: 30150, file: 'sof028-317-8488-1057-1-g-1.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30151, documentId: 30151, file: 'sof028-317-8488-1057-1-g-2.pdf', title: "Централни хали", docTypeId: 'g', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30152, documentId: 30152, file: 'sof028-317-8488-1057-1-p-1.pdf', title: "Централни хали", docTypeId: 'p', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 30153, documentId: 30153, file: 'sof028-317-8488-1057-1-s-1.pdf', title: "Централни хали", docTypeId: 's', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1001, documentId: 1001, file: '-sof028-317-267-1.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1002, documentId: 1002, file: '-sof028-317-267-2.pdf', docTypeId: 1, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1003, documentId: 1003, file: '-sof028-317-267-3.pdf', docTypeId: 3, date: '2011-07-18', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1004, documentId: 1004, file: '-sof028-317-267-4.pdf', docTypeId: 3, date: '2011-06-10', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1005, documentId: 1005, file: '-sof028-317-267-5.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1006, documentId: 1006, file: '-sof028-317-267-6.pdf', docTypeId: 3, date: '2005-08-22', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1007, documentId: 1007, file: '-sof028-317-267-7.pdf', docTypeId: 3, date: '2011-05-13', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1008, documentId: 1008, file: '-sof028-317-267-8.pdf', docTypeId: 3, date: '2005-08-22', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1009, documentId: 1009, file: '-sof028-317-267-9.pdf', docTypeId: 3, date: '2000-08-22', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1010, documentId: 1010, file: '-sof028-317-267-10.pdf', docTypeId: 7, date: '2000-05-25', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1011, documentId: 1011, file: '-sof028-317-267-11.pdf', docTypeId: 3, date: '2000-02-01', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1012, documentId: 1012, file: '-sof028-317-267-12.pdf', docTypeId: 3, date: '1999-06-16', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1013, documentId: 1013, file: '-sof028-317-267-13.pdf', docTypeId: 6, date: '1999-06-04', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1014, documentId: 1014, file: '-sof028-317-267-14.pdf', docTypeId: 3, date: '1999-06-02', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1015, documentId: 1015, file: '-sof028-317-267-15.pdf', docTypeId: 3, date: '1999-04-27', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1016, documentId: 1016, file: '-sof028-317-267-16.pdf', docTypeId: 7, date: '1999-05-25', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1017, documentId: 1017, file: '-sof028-317-267-17.pdf', docTypeId: 7, date: '1999-05-14', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1018, documentId: 1018, file: '-sof028-317-267-18.pdf', docTypeId: 3, date: '1999-02-12', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1019, documentId: 1019, file: '-sof028-317-267-19.pdf', docTypeId: 3, date: '1999-02-12', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1020, documentId: 1020, file: '-sof028-317-267-20.pdf', docTypeId: 3, date: '1999-01-19', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1021, documentId: 1021, file: '-sof028-317-267-21.pdf', docTypeId: 3, date: '1998-10-15', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1022, documentId: 1022, file: '-sof028-317-267-22.pdf', docTypeId: 3, date: '1998-10-15', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1023, documentId: 1023, file: '-sof028-317-267-23.pdf', docTypeId: 3, date: '1998-11-04', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1024, documentId: 1024, file: '-sof028-317-267-24.pdf', docTypeId: 3, date: '1998-09-01', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1025, documentId: 1025, file: '-sof028-317-267-25.pdf', docTypeId: 3, date: '1998-09-11', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1026, documentId: 1026, file: '-sof028-317-267-26.pdf', docTypeId: 3, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1027, documentId: 1027, file: '-sof028-317-267-27.pdf', docTypeId: 7, date: '1999-02-02', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1028, documentId: 1028, file: '-sof028-317-267-28.pdf', docTypeId: 7, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1029, documentId: 1029, file: '-sof028-317-267-29.pdf', docTypeId: 7, date: '1999-02-03', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1030, documentId: 1030, file: '-sof028-317-267-30.pdf', docTypeId: 3, date: '1997-11-27', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1031, documentId: 1031, file: '-sof028-317-267-32.pdf', docTypeId: 12, date: '1996-04-05', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1032, documentId: 1032, file: '-sof028-317-267-34.pdf', docTypeId: 3, date: '1996-04-17', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1033, documentId: 1033, file: '-sof028-317-267-35.pdf', docTypeId: 3, date: '1996-03-28', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1034, documentId: 1034, file: '-sof028-317-267-36.pdf', docTypeId: 3, date: '1996-03-14', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1035, documentId: 1035, file: '-sof028-317-267-37.pdf', docTypeId: 3, date: '1996-02-21', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1036, documentId: 1036, file: '-sof028-317-267-38.pdf', docTypeId: 3, date: '1995-07-27', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1037, documentId: 1037, file: '-sof028-317-267-40.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1038, documentId: 1038, file: '-sof028-317-267-41.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1039, documentId: 1039, file: '-sof028-317-267-42.pdf', docTypeId: 3, date: '1995-07-14', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1040, documentId: 1040, file: '-sof028-317-267-43.pdf', docTypeId: 3, date: '1995-05-18', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1041, documentId: 1041, file: '-sof028-317-267-44.pdf', docTypeId: 3, date: '1995-05-18', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1042, documentId: 1042, file: '-sof028-317-267-47.pdf', docTypeId: 3, date: '1995-05-18', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1043, documentId: 1043, file: '-sof028-317-267-49.pdf', docTypeId: 3, date: '1992-10-12', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1044, documentId: 1044, file: '-sof028-317-267-50.pdf', docTypeId: 12, date: '1992-11-16', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1045, documentId: 1045, file: '-sof028-317-267-53.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1046, documentId: 1046, file: '-sof028-317-267-54.pdf', docTypeId: 3, date: '1991-01-29', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1047, documentId: 1047, file: '-sof028-317-267-55.pdf', docTypeId: 7, date: '1990-10-30', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1048, documentId: 1048, file: '-sof028-317-267-57.pdf', docTypeId: 3, date: '1970-11-30', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1049, documentId: 1049, file: '-sof028-317-267-58.pdf', docTypeId: 11, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1050, documentId: 1050, file: '-sof028-317-267-59.pdf', docTypeId: 12, date: '1999-01-12', objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1051, documentId: 1051, file: '-sof028-317-267-60.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1052, documentId: 1052, file: '-sof028-317-267-61.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1053, documentId: 1053, file: '-sof028-317-267-62.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1054, documentId: 1054, file: '-sof028-317-267-63.pdf', docTypeId: 12, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },
            { id: 1055, documentId: 1055, file: '-sof028-317-267-64.pdf', docTypeId: 4, objectName: "Централни хали", objectNumber: "317-267", cadastreNumber: "68134.300.436", },

        ],


    document: {
        documentId: 123123,
        name: "Протокол за съгласуване",
        number: "111-222-333",
        history: [
            { historyId: 1, onDate: "2020-09-25 12:42:38.17322", username: "Александър Константинов" },
            { historyId: 2, onDate: "2012-03-22 12:42:38.17322", username: "Александър Константинов" },
            { historyId: 3, onDate: "2011-07-25 12:42:38.17322", username: "Александър Константинов" },

        ],
        additionalData: [
            {
                "id": "date",
                "type": "date",
                "label": "Дата на изготвяне",
                "value": "2001-12-11"
            },
            {
                "id": "description",
                "type": "text",
                "label": "Описание",
                "value": "Описание"
            }
        ]
    },

    additionalDataObjects: [
        {
            objectId: "protocol",
            label: "Протокол",
            elements: [
                {
                    "id": "name",
                    "type": "string",
                    "label": "Име"
                },
                {
                    "id": "bio",
                    "type": "text",
                    "label": "Биография"
                },
                {
                    "id": "accept",
                    "type": "check",
                    "label": "Съгласувано ли е?"
                },
                {
                    "id": "number",
                    "type": "number",
                    "label": "Брой сгради"
                },
                {
                    "id": "date",
                    "type": "date",
                    "label": "Дата на съгласуване"
                },
                {
                    "id": "email",
                    "type": "email",
                    "label": "Имейл"
                },
                {
                    "id": "checklist",
                    "type": "checklist",
                    "label": "Характеристики",
                    "items": [
                        { "text": "Сграда", "value": "1" },
                        { "text": "Двор", "value": "2" },
                        { "text": "Изба", "value": "3" }
                    ]
                }

            ]
        },
        {
            objectId: "project",
            label: "Проект",
            elements: [
                {
                    "id": "name",
                    "type": "string",
                    "label": "Име"
                },
                {
                    "id": "bio",
                    "type": "text",
                    "label": "Биография"
                },
                {
                    "id": "accept",
                    "type": "check",
                    "label": "Съгласувано ли е?"
                },
                {
                    "id": "number",
                    "type": "number",
                    "label": "Брой сгради"
                },
                {
                    "id": "date",
                    "type": "date",
                    "label": "Дата на съгласуване"
                },
                {
                    "id": "email",
                    "type": "email",
                    "label": "Имейл"
                },
                {
                    "id": "select",
                    "type": "select",
                    "label": "Категория",
                    "items": "exampleitems"
                },
                {
                    "id": "tree",
                    "type": "tree",
                    "label": "Населено място",
                    "items": "ekatte"
                },
                {
                    "id": "checklist",
                    "type": "checklist",
                    "label": "Характеристики",
                    "items": [
                        { "text": "Сграда", "value": "1" },
                        { "text": "Двор", "value": "2" },
                        { "text": "Изба", "value": "3" }
                    ]
                }

            ]
        }
    ],
    itemTypes: [
        {
            id: "exampleitems",
            label: "Примерни елементи"
        },
        {
            id: "ekatte",
            label: "Населени места"
        },

    ],
    autocompleteNumbers: ["111-222-333", "111-222-335", "111-222-338"],
    phases: [
        'огнезащита',
        'констр., работна, км и кмд',
        'идейна, архитектурна',
        'крр',
        'арх.',
        'ТП',
        'ген.сметка, работна',
        'АС - ПСД, работна',
        'АС, работна',
        'арх.заснемане, съществуващо положение',
        'паркоустройство и благоустройство, ТП',
        'становище',
        'ТП, конструкции',
        'ТП, пътна',
        'ТП, СКС и видеонаблюдение',
        'ТП, технологична',
        'ТП, възстановяване на огнезащита на метални носещи колони',
        'ТП, геодезия',
        'ТП, ВИК',
        'ТП, технология',
        'фотодокументация',
        'доклад',
        'експертиза, работна',
        'фасадна реставрация, арх.',
        'работна, арх.',
    ],
    normActs: [
        {
            normActId: 1,
            title: "ЗАКОН за културното наследство"
        },
        {
            normActId: 2,
            title: "ПРАВИЛНИК за устройството и дейността на Националния институт за недвижимо културно наследство"
        },
        {
            normActId: 3,
            title: "ЗАКОН за ратифициране на Конвенцията за опазване на подводното културно наследство"
        }
    ],
    NA: {
        normActId: 1,
        title: "ЗАКОН за културното наследство"
    }




}

export default FakeData;