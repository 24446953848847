import { FormControl, Grid, InputLabel, MenuItem, Select, Button } from '@material-ui/core';
import Comm from '../../comm/comm';
import React, { useEffect, useState } from 'react';
import IPPager from './IPPager';
import ClipLoader from "react-spinners/ClipLoader";
import { saveAs } from 'file-saver';
const IPGrid = ({ header, row, searchParams, refresh, hidePageSize }) => {
    let [page, setPage] = useState(1);
    let [pageSize, setPageSize] = useState(10);
    let [displayRows, setDisplayRows] = useState([]);
    let [found, setFound] = useState(0);
    let [totalPages, setTotalPages] = useState(0);
    let [progress, setProgress] = useState(false);

    useEffect(() => {
        let params = { ...searchParams.params };
        params.page = {
            page: page - 1,
            size: pageSize
        };

        setProgress(true)
        if (searchParams.method === "GET") {
            Comm.Instance().get(searchParams.func)
                .then(r => {

                    setDisplayRows(r.data.rows);
                    setFound(r.data.totalElements);
                    setTotalPages(r.data.totalPages);
                })
                .finally(() => {
                    setProgress(false);
                })
        }
        else {
            Comm.Instance().post(searchParams.func, params)
                .then(r => {

                    setDisplayRows(r.data.rows);
                    setFound(r.data.totalElements);
                    setTotalPages(r.data.totalPages);
                })
                .finally(() => {
                    setProgress(false);
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, pageSize, page, refresh]);

    useEffect(() => {
        setPage(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize])



    const exportFunc = (func, ext) => {
        let params = { ...searchParams.params };
        params.page = {
            page: page - 1,
            size: pageSize
        };

        setProgress(true);
        Comm.Instance().post(func, params, {
            responseType: 'blob'
        })
            .then(r => {
                console.log("r",r);
                saveAs(r.data, `export.${ext}`);
            })
            .finally(() => {
                setProgress(false);
            })


    }

    

    return (
        <React.Fragment>
            {
                progress &&

                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={true}
                />
            }

            {
                !progress &&
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        {
                            !hidePageSize &&
                            <FormControl fullWidth>
                                <InputLabel>Покажи</InputLabel>
                                <Select
                                    value={pageSize}
                                    onChange={(e) => setPageSize(e.target.value)}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {displayRows && <span>Открити са {found} елемента</span>}
                    </Grid>
                    {
                        searchParams.export &&
                        <Grid item xs={5}>
                            {
                                searchParams.export.xlsx &&
                                <Button variant="contained" color="primary" onClick={() => exportFunc(searchParams.export.xlsx, "xlsx")}>
                                    xlsx
                                </Button>
                            }
                        </Grid>
                    }

                    <Grid item xs={12}>
                        {header()}
                    </Grid>

                    {
                        displayRows.map((item, index) =>
                            <Grid item xs={12} key={Math.random()}>
                                {row(item)}
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <IPPager totalPages={totalPages} page={page} setPage={setPage} />
                    </Grid>

                </Grid>
            }
        </React.Fragment>
    );

}

export default IPGrid;