/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import Comm from '../comm/comm';
import IPGrid from '../controls/grid/IPGrid';
import ProtocolHeader from '../controls/grid/ProtocolHeader';
import ProtocolRow from '../controls/grid/ProtocolRow';


const ProtocolSelected = (props) => {
    let [searchParams,] = useState({
        func: "/backend/sis/secure/minutes/selected",
        params: {}, 
        method:"GET"
    });
    let [refresh, setRefresh] = useState(new Date());







    

    const Delete = (id) => {
        Comm.Instance().delete("backend/sis/secure/minutes?reference=" + id).then(r => {
            setRefresh(new Date());
        });
    }











    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Избрани протоколи</h2>
            



            <Paper>
                <IPGrid searchParams={searchParams}
                    refresh={refresh}
                    header={() => <ProtocolHeader></ProtocolHeader>}
                    row={(item) => <ProtocolRow item={item} deleteFunc={Delete}></ProtocolRow>}
                    hidePageSize={true}>

                </IPGrid>
            </Paper>

        </div>
    );
}


export default ProtocolSelected;