import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import DossierSearch from './components/DossierSearch';
import Dossier from './components/Dossier';
import LogSearch from './components/LogSearch';
import RedirectTo from './components/RedirectTo';
import ProtocolSearch from './components/ProtocolSearch';
import Protocol from './components/Protocol';
import DocumentSearch from './components/DocumentSearch';
import Document from './components/Document';
import ProjectSearch from './components/ProjectSearch';
import Project from './components/Project';
import DataDesigner from './components/DataDesigner';
import Ocr from './components/Ocr';
import { RecoilRoot, } from 'recoil';
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { ThemeProvider } from '@material-ui/core';
import NASearch from './components/NASearch';
import NADisplay from './components/NADisplay';
import DocumentSelected from './components/DocumentSelected';
import DossierSelected from './components/DossierSelected';
import ProtocolSelected from './components/ProtocolSelected';
import ProjectSelected from './components/ProjectSelected';
import Journal from './components/Journal';

const App = props => {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

    //console.log('Hello ' + (process.env.REACT_APP_NAME || "vuiche2"));
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RecoilRoot>
                <BrowserRouter basename={baseUrl}>
                    <Switch>

                        <Layout>

                            <Route path='/' component={DossierSearch} exact />
                            <Route path='/Dossier/:dossierId/:historyId' component={Dossier} exact />
                            <Route path='/Dossier/:dossierId' component={Dossier} exact />
                            <Route path='/Dossier' component={Dossier} exact />
                            
                            <Route path='/DossierSelected' component={DossierSelected} />
                            <Route path='/DossierSearch' component={DossierSearch} />
                            <Route path='/LogSearch' component={LogSearch} />
                            <Route path='/RedirectTo/:path' component={RedirectTo} />
                            <Route path='/ProtocolSearch' component={ProtocolSearch} />
                            <Route path='/ProtocolSelected' component={ProtocolSelected} />
                            
                            <Route path='/Protocol/:protocolId/:versionId' component={Protocol} exact />
                            <Route path='/Protocol/:protocolId' component={Protocol} exact />
                            <Route path='/Protocol' component={Protocol} exact />
                            <Route path='/DocumentSearch' component={DocumentSearch} />
                            <Route path='/DocumentSelected' component={DocumentSelected} />
                            <Route path='/Document/:documentId' component={Document} exact />
                            <Route path='/Document/:documentId/:versionId' component={Document} exact />
                            <Route path='/Document' component={Document} exact />
                            <Route path='/ProjectSearch' component={ProjectSearch} />
                            <Route path='/ProjectSelected' component={ProjectSelected} />
                            
                            <Route path='/DataDesigner' component={DataDesigner} />
                            <Route path='/Ocr/:documentId' component={Ocr} exact />
                            <Route path='/NASearch' component={NASearch} />
                            <Route path='/NA/:naid' component={NADisplay} />
                            <Route path='/Project/:projectId/:versionId' component={Project} exact />
                            <Route path='/Project/:projectId' component={Project} exact />
                            <Route path='/Project' component={Project} exact />
                            <Route path='/Journal' component={Journal} exact />

                        </Layout>
                    </Switch>
                </BrowserRouter>

            </RecoilRoot>
        </ThemeProvider>
    )



}

export default App;
