import React, { useState } from 'react';

const IPTB = (props) => {

    const [val, setVal] = useState(props.obj.value);
    const change = (val) => {
        setVal(val);
        props.setValue(props.obj.id, val)
    }

    return (
        <div className="form-group col-md-12">
            <label>{props.obj.label}</label>
            <input type="text" value={val} onChange={(e) => change(e.target.value)} className="form-control" readOnly={ props.readonly} />
        </div>

    );
}


export default IPTB;