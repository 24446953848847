import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Comm from '../../comm/comm';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const ProjectRow = ({ item, deleteFunc}) => {
    let [selected, setSelected] = useState(item.selected);

    const changeSelection = (id) => {
        Comm.Instance().post("/backend/sis/secure/project/selected", {
            "projectId": id,
            "selected": !selected
        });
        setSelected(!selected);

    }

    
    return (

        <Grid container spacing={0}>
            <Grid item xs={2}>
                {item.year}
            </Grid>
            <Grid item xs={2}>
                {item.registryIndexOld}
            </Grid>
            <Grid item xs={6}>

                <Link to={`Project/${item.id}`}>
                    <Typography align="left">
                        {item.name}
                    </Typography>
                </Link>
            </Grid>
            <Grid item xs={1}>
                {!selected && <Button onClick={() => changeSelection(item.id)} ><CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon></Button>}
                {selected && <Button onClick={() => changeSelection(item.id)} ><CheckBoxIcon></CheckBoxIcon></Button>}
            </Grid>
            <Grid item xs={1}>
                <Button onClick={() => deleteFunc(item.id)}><DeleteForeverIcon></DeleteForeverIcon></Button>
            </Grid>

        </Grid>
    )
}

export default ProjectRow;