/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import TB from '../controls/form/TB';
import CB from '../controls/form/CB';
import Comm from '../comm/comm';
import SelectedObjects from '../controls/SelectedObjects';
import IPGrid from '../controls/grid/IPGrid';
import DocumentHeader from '../controls/grid/DocumentHeader';
import DocumentRow from '../controls/grid/DocumentRow';
import { Redirect } from 'react-router-dom';


const DocumentSearch = (props) => {
    let [protocol, setProtocol] = useState(0);
    let [project, setProject] = useState(0);
    let [dossier, setDossier] = useState(0);
    let [inNum, setInNum] = useState("");
    let [ss, setss] = useState("");
    let [title, setTitle] = useState("");
    let [notConnected, setNotConnected] = useState(false);
    let [searchParams, setSearchParams] = useState({
        func: "/backend/sis/secure/file/top/active/10",
        export: {
            xlsx: "/backend/sis/secure/file/search/xlsx"
        },
        params: {}
    });
    let [refresh, setRefresh] = useState(new Date());
    let [newDoc, setNewDoc] = useState(false);






    const Search = () => {
        let searchData = {
            "application-reference": inNum,
            "orphan": notConnected,
            "ss": ss === "" ? null : ss,
            "title": title === "" ? null : title,
            "artifactReference": dossier == "0" ? null : dossier,
            "minutesReference": protocol == "0" ? null : protocol,
            "projectReference": project == "0" ? null : project,
        };

        setSearchParams({
            func: "backend/sis/secure/file/search",
            export: {
                xlsx: "/backend/sis/secure/file/search/xlsx"
            },
            params: searchData
        })

    };




    const Delete = (id) => {
        Comm.Instance().delete("backend/sis/secure/file?reference=" + id).then(r => {
            setRefresh(new Date());
        });
    }


    if (newDoc) {
        return (<Redirect to={"Document"} />);
    }








    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Търсене на документи</h2>
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <TB label="Описание/тип на документа" value={title} change={setTitle} />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectedObjects label="Досие" value={dossier} setValue={setDossier} objectType="dossiers" />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectedObjects label="Проект" value={project} setValue={setProject} objectType="projects" />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectedObjects label="Протокол" value={protocol} setValue={setProtocol} objectType="protocols" />
                    </Grid>

                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <TB label="Номер на преписка" value={inNum} change={setInNum} />
                    </Grid>
                    <Grid item xs={6}>
                        <TB label="В текста" value={ss} change={setss} />
                    </Grid>
                    <Grid item xs={3}>
                        <CB label="Несвързани с досие" checked={notConnected} change={setNotConnected} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => Search()}>
                            Търсене
                        </Button>
                    </Grid>
                    <Grid item xs={8}>

                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="secondary" onClick={() => setNewDoc(true)}>
                            Нов документ
                        </Button>
                    </Grid>
                </Grid>
            </Paper>




            <Paper>
                <IPGrid searchParams={searchParams}
                    refresh={refresh}
                    header={() => <DocumentHeader></DocumentHeader>}
                    row={(item) => <DocumentRow item={item} deleteFunc={Delete}></DocumentRow>}>

                </IPGrid>
            </Paper>

        </div>
    );
}


export default DocumentSearch;