import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import FakeData from '../fakedata/FakeData';


const DataDesignerElement = (props) => {

    let [content, setContent] = useState({...props.content});
    let [items, setItems] = useState();

    const EditContent = (id, value) => {
        content[id] = value;
        setContent({ ...content });
    }

    const SetRow = (arr, id, idProperty, property, value) => {
        var array = content[arr];
        var element = array.filter(x => x[idProperty] === id)[0];
        element[property] = value;
        setContent({ ...content });
    }

    const AddRow = (arr, obj) => {
        let array = content[arr];
        array.push(obj);
        setContent({ ...content });
    }



    const DelRow = (arr, id, idProperty) => {
        let array = content[arr];
        content[arr] = array.filter(x => x[idProperty] !== id);
        setContent({ ...content });
    }

    const fetchData = async () => {
        setItems(FakeData.itemTypes);

    };







    useEffect(() => {

        fetchData();
    }, []);

    if (!items)
        return (
            <div className="container">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={true}
                />
            </div>
        );

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <label>Индетификатор</label>
                    <input className="form-control" type="text" value={content.id} onChange={(e) => EditContent("id", e.target.value)} readOnly={!props.caneditid}></input>
                </div>
                <div className="col-md-12">
                    <label>Етикет</label>
                    <input className="form-control" type="text" value={content.label} onChange={(e) => EditContent("label", e.target.value)}></input>
                </div>
                {
                    (props.type === "select" || props.type === "tree") ?
                        <div className="col-md-12">
                            <label>Вид списък с данни</label>
                            <select className="form-control" value={content.items} onChange={(e) => EditContent("items", e.target.value)}>
                                <option value="0"></option>
                                {
                                    items.map(i => <option value={i.id}>{i.label}</option>)
                                }
                            </select>
                        </div>
                        : null

                }
                {
                    props.type === "checklist" ?
                        <div className="col-md-12">
                            <table className="tbl tbl-striped tbl-bordered">
                                <thead>
                                    <tr>
                                        <th>
                                            Идентификатор
                                    </th>
                                        <th>
                                            Етикет
                                    </th>
                                        <th>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        content.items.map(i =>
                                            <tr>
                                                <td><input className="form-control" type="text" value={i.value} onChange={(e) => SetRow("items", i.value, "value", "value", e.target.value)}></input></td>
                                                <td><input className="form-control" type="text" value={i.text} onChange={(e) => SetRow("items", i.value, "value", "text", e.target.value)}></input></td>
                                                <td><button className="btn btn-danger" onClick={() => DelRow("items", i.value, "value")}>-</button></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <button className="btn btn-primary" onClick={() => AddRow("items", { "text": "Нова опция", "value": "" + Math.floor((Math.random() * 10000) + 1) })}>+</button>
                        </div>
                        : null

                }


            </div>
            <div className="row">
                <div className="col-md-12">
                    <button className="btn btn-primary" onClick={() => props.saveFunc(content) }>Запис</button>
                </div>
            </div>
        </>

    )
}

export default DataDesignerElement;