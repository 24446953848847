/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import Comm from '../comm/comm';
import IPGrid from '../controls/grid/IPGrid';
import ProjectHeader from '../controls/grid/ProjectHeader';
import ProjectRow from '../controls/grid/ProjectRow';


const ProjectSelected = (props) => {
    let [searchParams,] = useState({
        func: "/backend/sis/secure/project/selected",
        params: {}, 
        method:"GET"
    });
    let [refresh, setRefresh] = useState(new Date());







    

    const Delete = (id) => {
        Comm.Instance().delete("backend/sis/secure/project?reference=" + id).then(r => {
            setRefresh(new Date());
        });
    }











    return (
        <div className="container">
            <h2 className="appeal-title mb-5">Избрани проекти</h2>
            



            <Paper>
                <IPGrid searchParams={searchParams}
                    refresh={refresh}
                    header={() => <ProjectHeader></ProjectHeader>}
                    row={(item) => <ProjectRow item={item} deleteFunc={Delete}></ProjectRow>}
                    hidePageSize={true}>

                </IPGrid>
            </Paper>

        </div>
    );
}


export default ProjectSelected;