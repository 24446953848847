import React from 'react';
import IPTB from '../controls/IPTB';
import IPTextArea from '../controls/IPTextArea';
import IPCheck from '../controls/IPCheck';
import IPSelect from '../controls/IPSelect';
import IPTree from '../controls/IPTree';
import IPCheckList from '../controls/IPCheckList';
import IPEmail from '../controls/IPEmail';
import IPNumber from '../controls/IPNumber';
import IPDate from '../controls/IPDate';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';






const PropertiesContainer = (props) => {


    const returnElement = (obj) => {

        switch (obj.type) {
            case "string": return (<IPTB obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "date": return (<IPDate obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "email": return (<IPEmail obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "number": return (<IPNumber obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "text": return (<IPTextArea obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "check": return (<IPCheck obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "select": return (<IPSelect obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "tree": return (<IPTree obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            case "checklist": return (<IPCheckList obj={obj} setValue={props.setPropertyValue} key={obj.id} readonly={props.readonly} />);
            default: return (<div>Uknown element</div>);
        }
    }




    return (
        <Container maxWidth="sm" spacing={2}>
            <Grid container spacing={3}>

                {
                    props.obj.map((item, index) => <Grid item xs={12} key={index}>{ returnElement(item) }</Grid>
                    )
                }
            </Grid>
        </Container>

    );
}


export default PropertiesContainer;