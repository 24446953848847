import React, { useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import Tools from '../tools/tools'
import { Tree } from 'primereact/tree';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const IPTree = (props) => {

    const [val, setVal] = useState(props.obj.value);
    const [items, setItems] = useState(null);

 

    if (!items) {
        Tools.ReloadIfOld(props.obj.items, setItems);

        return (
            <div className="form-group col-md-12">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={!items}
                />
            </div>
        )
    }





    const change = (val) => {
        setVal(val);
        props.setValue(props.obj.id, val)
    }


    var exp = {};
    const getExpandAll = (roots, exp) => {
        roots.forEach(i => {
            exp[i.key] = true;
            if (i.children) {
                getExpandAll(i.children, exp);
            }
        })
    };
    getExpandAll(items, exp);







    return (
        <div className="form-group col-md-12">
            <label>{props.obj.label}</label>


            <Tree value={items} selectionMode="single" selectionKeys={val} onSelectionChange={e => change(e.value)} disabled={props.readonly} expandedKeys={exp} max-height="30vh"/>

        </div>

    );
}


export default IPTree;