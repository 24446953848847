/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TB from './form/TB';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DDL from './form/DDL';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Comm from '../comm/comm';
import ModalMoveDocument from './ModalMoveDocument';

const DocumentList = (props) => {
    const step = 20;
    let [showCount, setShowCount] = useState(step);
    let [ss, setSS] = useState("");
    let [documents, setDocuments] = useState([])
    let [selectedDocument, setSelectedDocument] = useState(0)
    let [selectedDocuments, setSelectedDocuments] = useState([])
    let [stage, setStage] = useState("");
    let [discipline, setDiscipline] = useState("");
    let [showMoveDialog, setShowMoveDialog] = useState({ show: false, documentId: null });

    const filterDocuments = () => {
        let result = (props.documents || [])
            .filter(x => (ss || "") === "" || x.title.toLowerCase().indexOf(ss.toLowerCase()) > -1)
            .sort((a, b) => a.stage < b.stage ? -1 : 1)
            ;
        //.slice(0, Math.min(props.documents.length, showCount) - 1);
        setDocuments([...result]);

    }

    const addDocument = () => {
        let doc = { ...selectedDocuments.find(d => d.documentId === selectedDocument) };
        doc.stage = stage;
        doc.discipline = discipline;
        props.addFunc(doc);
        setSelectedDocument(0);
    }

    const moveDocument = (id) => {
        if (id) {
            props.deleteFunc(id, true);
        }

        setShowMoveDialog({ show: false, documentId: null });
    }

    useEffect(() => {
        Comm.Instance().get("/backend/sis/secure/file/selected").then(r => setSelectedDocuments(r.data.rows));


    }, [])

    useEffect(() => {

        (props.documents || []).forEach(doc => {
            if (doc.name) {
                doc.title = doc.name;
            }
            if (doc.id) {
                doc.documentId = doc.id;
            }
        });

    }, [props.documents])



    useEffect(() => {
        filterDocuments();
    }, [props.documents])



    return (
        <div className="container">
            <Grid
                container
                spacing={3}
            >

                <Grid item xs={10}>

                    <TB label="Търсене..." value={ss} change={(v) => setSS(v)}></TB>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={() => filterDocuments()}><SearchIcon></SearchIcon></Button>
                </Grid>
                {
                    !props.readonly &&
                    <>
                        <Grid item xs={props.hasPhase ? 6 : 10}>

                            <DDL label="Избери документ"
                                value={selectedDocument}
                                change={(e) => setSelectedDocument(e)}
                                items={selectedDocuments}
                                idfield="documentId"
                                labelfield="name"

                            />
                        </Grid>
                        {props.hasPhase &&
                            <Grid item xs={2}>
                                <TB label="Фаза" value={stage} change={(v) => setStage(v)}></TB>
                            </Grid>
                        }
                        {props.hasPhase &&
                            <Grid item xs={2}>
                                <TB label="Част" value={discipline} change={(v) => setDiscipline(v)}></TB>
                            </Grid>
                        }
                        <Grid item xs={2}>
                            {
                                selectedDocument !== 0 && <Button onClick={() => addDocument()}><AddIcon></AddIcon></Button>
                            }

                        </Grid>
                    </>
                }

                <Grid item xs={12}>
                    <b>{documents.length} документа</b>
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            documents && documents.length > 0 &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <b>Дата</b>
                                </Grid>
                                {
                                    props.hasPhase &&
                                    <Grid item xs={2}>
                                        <b>Фаза</b>
                                    </Grid>
                                }
                                {
                                    props.hasPhase &&
                                    <Grid item xs={2}>
                                        <b>Част</b>
                                    </Grid>
                                }
                                <Grid item xs={4}>
                                    <b>Документ</b>
                                </Grid>
                                <Grid item xs={2}>

                                </Grid>
                                {
                                    !props.hasPhase &&
                                    <Grid item xs={4}>

                                    </Grid>
                                }
                            </React.Fragment>
                        }
                        {
                            documents.slice(0, Math.min(documents.length, showCount)).map(x =>
                                <React.Fragment key={x.documentId}>
                                    <Grid item xs={2}>
                                        {x.date && moment(x.date, "YYYY-MM-DD").format("DD.MM.YYYY")}
                                    </Grid>
                                    {
                                        props.hasPhase &&
                                        <Grid item xs={2}>
                                            {x.stage}
                                        </Grid>
                                    }
                                    {
                                        props.hasPhase &&
                                        <Grid item xs={2}>
                                            {x.discipline}
                                        </Grid>
                                    }
                                    <Grid item xs={4}>
                                        <a href={process.env.REACT_APP_API + "backend/sis/secure/file?reference=" + x.documentId} target="_blank">{x.title || x["file-name"]}</a>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {
                                            !props.readonly && <Button onClick={() => props.deleteFunc(x.documentId)}><DeleteForeverIcon></DeleteForeverIcon></Button>
                                        }
                                        {
                                            !props.readonly && <Button onClick={() => setShowMoveDialog({ show: true, documentId: x.documentId })}><ArrowForwardIcon></ArrowForwardIcon></Button>
                                        }
                                    </Grid>
                                    {
                                        !props.hasPhase &&
                                        <Grid item xs={4}>

                                        </Grid>
                                    }
                                </React.Fragment>
                            )}
                    </Grid>
                    {
                        documents.length > showCount && <Button onClick={() => setShowCount(showCount + step)}><MoreHorizIcon></MoreHorizIcon></Button>
                    }
                </Grid>
            </Grid>
            <ModalMoveDocument header="Преместване на документ" visible={showMoveDialog.show} closeFunc={(id) => moveDocument(id)} documentid={showMoveDialog.documentId}></ModalMoveDocument>
        </div>

    );
}

export default DocumentList;