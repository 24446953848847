import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import Comm from '../comm/comm';
import DDL from './form/DDL';


const SelectedObjects = ({ value, setValue, objectType, label }) => {

    var [items, setItems] = useState();

    useEffect(() => {

        switch (objectType) {
            case "protocols":
                Comm.Instance().get("/backend/sis/secure/minutes/selected").then(r => setItems(r.data.rows.map(i => { return { key: i.id, label: i.name }; })));
                break;
            case "projects":
                Comm.Instance().get("/backend/sis/secure/project/selected").then(r => setItems(r.data.rows.map(i => { return { key: i.id, label: i.name }; })));
                break;
            case "dossiers":
                Comm.Instance().get("/backend/sis/secure/artifact/selected").then(r => setItems(r.data.rows.map(i => { return { key: i.id, label: i.main.number + ' ' + i.main.name }; })));
                break;
            default: setItems([]); break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!items) {

        return (
            <div className="form-group col-md-12">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={!items}
                />
            </div>
        )
    }



    return (
        <div className="form-group col-md-12">
            <DDL label={label} value={value} change={e => setValue(e)}
                items={items}
                idfield="key"
                labelfield="label"></DDL>

        </div>

    );
}

export default SelectedObjects;