import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import Tools from '../tools/tools';
import { toast } from 'react-toastify';
import ReactJson from 'react-json-view'
import FakeData from '../fakedata/FakeData';
import { Button, Grid, Paper } from '@material-ui/core';
import DateTB from '../controls/form/DateTB';
import DDL from '../controls/form/DDL';

const LogSearch = (props) => {
    let [date, setDate] = useState(Tools.NormalizeDate(new Date()));
    let [userId, setUserId] = useState("0");
    let [users, setUsers] = useState(null);
    let [rows, setRows] = useState(null);

    const fetchData = async () => {
        setUsers(null);
        // Comm.Instance().get(`user`).then(result => {
        //     setUsers(result.data);

        // }).catch(error => Tools.ProcessError(error))
        setUsers(FakeData.users);
    };
    useEffect(() => {

        fetchData();
    }, []);



    const Search = () => {
        if (!date || date === "") {
            toast.error("Моля, изберете дата");
            return;
        }

        setRows([]);
        

        // Comm.Instance().post(`log`, data).then(result => {
        //     setRows(result.data);

        // }).catch(error => Tools.ProcessError(error))
        setRows(FakeData.log);
    };


    const collapse = (logId) => {
        let log = rows.rows.filter(x => x.logId === logId)[0];
        if (log.show) {
            log.show = false;
        }
        else {
            log.show = true;
        }
        setRows({...rows});
    }

    if (!users) {
        return (
            <div className="form-group col-md-12">
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={!users}
                />
            </div>
        )
    }



    return (

        <div className="container">
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <DateTB value={date} change={setDate} />
                    </Grid>
                    <Grid item xs={4}>
                        <DDL label="Потребител" value={userId} change={(e) => setUserId(e)}
                            items={users}
                            idfield="username"
                            labelfield="fullname"></DDL>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={() => Search()}>
                            Търсене
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {
                rows ?
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <div className="alert alert-secondary" role="alert">
                                Открити са {rows.length} записа
                            </div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Потребител</th>
                                        <th>Действие</th>
                                        <th>Описание</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rows.rows.map(i =>
                                            <tr key={i.logId}>
                                                <td><p>{i.userId}</p><small>{Tools.FormatDate(i.onDate)}</small></td>
                                                <td>{i.action}</td>
                                                <td>
                                                    <button className="btn btn-link" onClick={() => collapse(i.logId)}>...</button>
                                                    {
                                                        i.show ?
                                                            <ReactJson src={JSON.parse(i.data)} />
                                                            : null
                                                    }
                                                </td>

                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : null
            }


        </div>

    );
}


export default LogSearch;