import { selector, } from 'recoil';
import ToastMessageAtom from '../atoms/ToastMessageAtom';

const ToastMessageSelector = selector({
    key: 'toastMessageSelector', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const text = get(ToastMessageAtom);

        return text;
    },
});

export default ToastMessageSelector;