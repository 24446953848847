import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const JournalHeader = () => {
    return (

        <Grid container spacing={0}>
            <Grid item xs={2}>
                <Typography variant="h6" gutterBottom>Дата</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>Идентификатори</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" gutterBottom>Потребител</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" gutterBottom>Статус</Typography>
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor': '#b9d5ff91' }}>
                <Typography variant="h6" gutterBottom>Субект</Typography>
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor': '#b9d5ff91' }}>
                <Typography variant="h6" gutterBottom>Действие</Typography>
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor': '#b9d5ff91' }}>
                <Typography variant="h6" gutterBottom>Адрес</Typography>
            </Grid>
            <Grid item xs={3} style={{ 'backgroundColor': '#b9d5ff91' }}>
                <Typography variant="h6" gutterBottom>Агент</Typography>
            </Grid>


        </Grid>
    )
}

export default JournalHeader;