import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectTo = (props) => {
    return (
        <Redirect to={"/" + decodeURIComponent(props.match.params.path)} />
    );

}

export default RedirectTo;