import React, { useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { toast } from 'react-toastify';
import ModalText from '../controls/ModalText';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const text = "културна стратегия община пловдив\nсъдържание\n резюмe \n как беше създадена тази стратегия ";
const Ocr = (props) => {

    let [pageNumber, setPageNumber] = useState(1);
    let [numPages, setNumPages] = useState(null);
    let [tags, setTags] = useState({});
    let [tagModalVisible, setTagModalVisible] = useState(false);


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const saveTags = (text) => {
        tags[pageNumber] = text;
        setTags({ ...tags });
        setTagModalVisible(false);
    }



    return (
        <div className="container">

            <h2 className="appeal-title mb-5">Разпознати данни</h2>
            <div className="row">
                <div className="col-6">
                    <Document file="/images/r238.pdf" onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    {
                        pageNumber > 1 ?
                            <button className="btn btn-light" onClick={() => setPageNumber(pageNumber - 1)}>&lt;&lt;</button>
                            : null
                    }

                    {
                        numPages && pageNumber < numPages ?
                            <button className="btn btn-light" onClick={() => setPageNumber(pageNumber + 1)}>&gt;&gt;</button>
                            : null
                    }
                    <button className="btn btn-primary" onClick={() => setTagModalVisible(true)}><i class="fa fa-tags" aria-hidden="true"></i></button><br />
                    {tags[pageNumber]}
                </div>
                <div className="col-6">
                    <textarea className="form-control" rows="35" defaultValue={text}></textarea>
                </div>
            </div>
            <div className="row">
                <div className="col-6 offset-6">
                    <button className="btn btn-primary" onClick={() => toast.success("Успешен запис")}>Запис</button>
                </div>
            </div>
            <ModalText display={tagModalVisible} closeFunc={() => setTagModalVisible(false)} saveFunc={(x) => saveTags(x)} value={tags[pageNumber] || ""} title="Тагове" />
        </div>);
}

export default Ocr;