import { TextField } from '@material-ui/core';
import React from 'react';

const TB = (props) => {

    return <TextField label={props.label} variant="outlined" value={props.value || ""} onChange={(e) => props.change(e.target.value)} fullWidth
        InputProps={{
            readOnly: props.readonly,
        }}
    />
}

export default TB;
